import type { ConfirmDialogOptions } from "@verbleif/lib";
import * as Sentry from "@sentry/vue";
import { useConfirmDialog } from "@verbleif/lib";
import { api } from "@verbleif/shared";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const iconForEndpoint: Record<string, ["fas" | "far" | "fad" | "fal", string]> = {
  "/locations": ["fas", "store-slash"],
  "default": ["fas", "user-slash"],
};

interface UseActiveToggleArguments {
  endpoint: string
  translationKey: string
  load?: (() => Promise<any>) | null
}

export function useActiveToggle({
  endpoint,
  translationKey,
  load = null,
}: UseActiveToggleArguments) {
  const { t } = useI18n();
  const { error, success } = useToast();
  const dialog = useConfirmDialog();

  async function showActiveToggle(item: any) {
    if (item.active) {
      const name
        = typeof item.name === "object" ? item.name.join(" ") : item.name;
      const options: ConfirmDialogOptions = {
        title: t(`${translationKey}.active_toggle_title`, { name }),
        shouldConfirm: false,
        messageIcon: iconForEndpoint[endpoint] || iconForEndpoint.default,
        message: t(`${translationKey}.active_toggle_confirm_text`),
        continueIcon: iconForEndpoint[endpoint] || iconForEndpoint.default,
        continueText: t(`${translationKey}.active_toggle_confirm_btn`),
        cancelText: t("base.cancel"),
      };
      try {
        await dialog.open({ options, async: true });
      } catch (cancel) {
        console.error(cancel);
        return;
      } finally {
        dialog.close();
      }
    }

    toggleActive(item);
  }

  function toggleActive(item: any) {
    item.active = +!item.active;
    api
      .patch(`${endpoint}/${item.id}`, { active: item.active })
      .then(() => {
        success(t(`${translationKey}.active_toggle_success_${item.active}`));
      })
      .catch((e: any) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }));
        item.active = +!item.active;
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        if (typeof load === "function") {
          load(); // Call load if it's a function
        }
      });
  }

  return {
    showActiveToggle,
  };
}
