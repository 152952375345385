import { createRightsStore, useAuthStore } from "@verbleif/shared";

const authStore = useAuthStore();
const rightsStore = createRightsStore(authStore);

export function useRightsStore() {
  return rightsStore;
}

export default useRightsStore;
