import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "49",
  height: "49",
  viewBox: "0 0 49 44"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "nonzero"
    }, [
      _createElementVNode("path", {
        fill: "#FFF",
        d: "M26.4 43 40.756.344H29.27l-8.496 31.054L12.31.344H.912L15.15 43z",
        class: "v"
      }),
      _createElementVNode("path", {
        fill: "#4caf50",
        d: "M43.217 43.322q2.343 0 3.896-1.391t1.553-3.56-1.553-3.56q-1.553-1.39-3.896-1.391-2.373 0-3.911 1.406-1.539 1.407-1.538 3.545 0 2.14 1.538 3.545 1.538 1.407 3.91 1.406"
      })
    ], -1)
  ])))
}
export default { render: render }