import Features from "./Features.vue";

export const routes = [
  {
    path: "/features",

    name: "features",
    component: Features,
  },
];

export default routes;
