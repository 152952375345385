<script>
import { useI18n } from "vue-i18n";

export default {
  name: "ClientsContainer",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>

<template>
  <div class="clients-container">
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" src="./Clients.scss" scoped></style>

<style lang="scss" scoped>
.clients-container {
  width: 100%;
  height: 100%;
  display: flex;
  .content {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 18px;
  }
}
</style>
