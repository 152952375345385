import departments from "./departments.json";
import groups from "./groups.json";
import means from "./means.json";
import myAccount from "./myAccount.json";
import objects from "./objects.json";
import sidebar from "./sidebar.json";
import sorts from "./sorts.json";
import spaces from "./spaces.json";
import teams from "./teams.json";
import users from "./users.json";

export default {
  sidebar,
  myAccount,
  users,
  departments,
  teams,
  groups,
  spaces,
  means,
  objects,
  sorts,
};
