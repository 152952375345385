import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAnalytics as fadAnalytics,
  faBallotCheck as fadBallotCheck,
  faBuilding as fadBuilding,
  faCodeCompare as fadCodeCompare,
  faCog as fadCog,
  faDiagramSubtask as fadDiagramSubtask,
  faFileImport as fadFileImport,
  faFlag as fadFlag,
  faHouse as fadHouse,
  faListTree as fadListTree,
  faSpinner as fadSpinner,
  faStore as fadStore,
  faSuitcase as fadSuitCase,
  faTachometerAltFastest as fadTachometerAltFastest,
  faUserHelmetSafety as fadUserHelmetSafety,
  faUsers as fadUsers,
  faUsersCog as fadUsersCog,
  faUserTie as fadUserTie,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faCheckCircle as falCheckCircle,
  faExclamationTriangle as falExclamationTriangle,
  faFile as falFile,
  faFileArchive as falFileArchive,
  faFileExcel as falFileExcel,
  faFilePdf as falFilePdf,
  faFileVideo as falFileVideo,
  faFileWord as falFileWord,
  faImage as falImage,
  faInfoCircle as falInfoCircle,
  faTimes as falTimes,
} from "@fortawesome/pro-light-svg-icons";

import {
  faArchive as farArchive,
  faBell as farBell,
  faBroom as farBroom,
  faBrush as farBrush,
  faCalendar as farCalendar,
  faCheckCircle as farCheckCircle,
  faCopy as farCopy,
  faCreditCard as farCreditCard,
  faExchange as farExchange,
  faExclamationCircle as farExclamationCircle,
  faFileExport as farFileExport,
  faFileInvoice as farFileInvoice,
  faHome as farHome,
  faLocation as farLocation,
  faLock as farLock,
  faMask as farMask,
  faPencil as farPencil,
  faPlus as farPlus,
  faPlusCircle as farPlusCircle,
  faRandom as farRandom,
  faRedo as farRedo,
  faRepeat as farRepeat,
  faSearch as farSearch,
  faStoreSlash as farStoreSlash,
  faTags as farTags,
  faTh as farTh,
  faThLarge as farThLarge,
  faTimes as farTimes,
  faTimesCircle as farTimesCircle,
  faTrash as farTrash,
  faUpload as farUpload,
  faUserFriends as farUserFriends,
  faUsers as farUsers,
  faUsersClass as farUsersClass,
  faUserShield as farUserShield,
  faUserSlash as farUserSlash,
  faUsersSlash as farUsersSlash,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faAdjust,
  faArrowDownWideShort,
  faBallotCheck,
  faBan,
  faBell,
  faBuilding,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faComment,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faEllipsisH,
  faExchange,
  faExclamation,
  faExternalLink,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFlag,
  faFolder,
  faFolderOpen,
  faHashtag,
  faInfoCircle,
  faKeyboard,
  faLock,
  faPaperPlane,
  faPencil,
  faPlus,
  faRedo,
  faSave,
  faSortAlphaDown,
  faSortAlphaUp,
  faSpinner,
  faStoreSlash,
  faTasks,
  faTimes,
  faTrash,
  faUndo,
  faUpload,
  faUser,
  faUsers,
  faUserSlash,
  faUsersSlash,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  falCheckCircle,
  fadUserTie,
  fadAnalytics,
  fadCog,
  fadBallotCheck,
  fadFlag,
  fadTachometerAltFastest,
  farUserFriends,
  farUsers,
  farExclamationCircle,
  farUsersClass,
  farCreditCard,
  farFileInvoice,
  farUserShield,
  faBuilding,
  faUser,
  faLock,
  faSpinner,
  faSave,
  faEye,
  faEyeSlash,
  farLocation,
  farTh,
  farThLarge,
  farHome,
  farBrush,
  farTags,
  farLock,
  faBell,
  farBell,
  farTimes,
  farCheckCircle,
  farTimesCircle,
  farRedo,
  faAdjust,
  faPlus,
  farPlusCircle,
  faSortAlphaUp,
  faSortAlphaDown,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
  faHashtag,
  faPencil,
  faUserSlash,
  faTrash,
  falTimes,
  farUserSlash,
  faInfoCircle,
  faClock,
  faTimes,
  faPaperPlane,
  farTrash,
  farBroom,
  faBan,
  farUpload,
  faUpload,
  faCheck,
  faFolder,
  faCog,
  farPlus,
  faCalendar,
  faClock,
  faExternalLinkAlt,
  farPencil,
  falInfoCircle,
  falExclamationTriangle,
  faBallotCheck,
  faRedo,
  fadSpinner,
  falFilePdf,
  falFileWord,
  falFileExcel,
  falFileVideo,
  falFileArchive,
  falFile,
  falInfoCircle,
  faExclamation,
  faDoorOpen,
  faDoorClosed,
  farFileExport,
  farCopy,
  faFolderOpen,
  farArchive,
  falInfoCircle,
  faTasks,
  faFlag,
  faComment,
  faDownload,
  farCalendar,
  farSearch,
  farExchange,
  faExchange,
  faUsers,
  faExternalLink,
  falImage,
  farRandom,
  fadUsers,
  fadUsersCog,
  farMask,
  faKeyboard,
  farUsersSlash,
  faUsersSlash,
  farStoreSlash,
  faStoreSlash,
  faUndo,
  fadCodeCompare,
  fadHouse,
  fadBuilding,
  fadListTree,
  fadDiagramSubtask,
  fadFileImport,
  fadUserHelmetSafety,
  fadSuitCase,
  fadStore,
  farRepeat,
  faArrowDownWideShort,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
);
