import ClientsContainer from "@/features/Clients/ClientsContainer.vue";

export const routes = [
  {
    path: "/clients",

    name: "clients",
    component: ClientsContainer,
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "client-overview" },
      },
      {
        path: "",
        name: "client-overview",
        component: () => import("./ClientsOverview.vue"),
      },
      {
        path: "edit/:id",
        name: "client-edit",
        component: () => import("./Edit/ClientEdit.vue"),
      },
      // {
      //   path: 'subscription',
      //   name: 'subscription',
      //   component: () => import('./Personal/MyAccount')
      // },
      // {
      //   path: 'invoices',
      //   name: 'invoices',
      //   component: () => import('./Personal/MyAccount')
      // },
      // {
      //   path: 'users',
      //   name: 'users',
      //   component: () => import('./Clients/Clients')
      // },
      // {
      //   path: 'teams',
      //   name: 'teams',
      //   component: () => import('./Teams/Teams')
      // },
      // {
      //   path: 'departments',
      //   name: 'departments',
      //   component: () => import('./Departments/Departments')
      // },
      // {
      //   path: 'sorts',
      //   name: 'sorts',
      //   component: () => import('./Sorts/Sorts')
      // },
      // {
      //   path: 'groups',
      //   name: 'groups',
      //   component: () => import('./Groups/Groups')
      // },
      // {
      //   path: 'objects',
      //   name: 'objects',
      //   component: () => import('./Objects/Objects')
      // },
      // {
      //   path: 'spaces',
      //   name: 'spaces',
      //   component: () => import('./Spaces/Spaces')
      // },
      // {
      //   path: 'means',
      //   name: 'means',
      //   component: () => import('./Means/Means')
      // }
    ],
  },
];

export default routes;
