import { useConfirmDialog } from "@verbleif/lib";

export function useConfigOverrideDialog() {
  const dialog = useConfirmDialog();

  function showConfigOverrideDialog(event: MouseEvent) {
    if (event.shiftKey && event.altKey) {
      showDialog().then();
    }
  }

  async function showDialog() {
    return dialog.open({
      component: import("@/features/ConfigOverride/ConfigOverrideDialog.vue"),
    });
  }

  return {
    showConfigOverrideDialog,
  };
}
