import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  preserveAspectRatio: "xMidYMid",
  style: {"background":"0 0","display":"block","shape-rendering":"auto"},
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "50",
      cy: "50",
      r: "37",
      fill: "none",
      stroke: "#4CAF50",
      "stroke-dasharray": "174.35839227423352 60.119464091411174",
      "stroke-width": "8"
    }, [
      _createElementVNode("animateTransform", {
        attributeName: "transform",
        dur: "0.4s",
        keyTimes: "0;1",
        repeatCount: "indefinite",
        type: "rotate",
        values: "0 50 50;360 50 50"
      })
    ], -1)
  ])))
}
export default { render: render }