<script setup lang="ts">
import { refreshToken } from "@/core/refresh";
import Loader from "@/features/Loader/LoaderView.vue";
import { provideLoader } from "@/features/Loader/useLoader";
import NavbarContainer from "@/features/Navbar/NavbarContainer.vue";
import * as Sentry from "@sentry/vue";
import { provideConfirmDialog } from "@verbleif/lib";
import { useAuthStore } from "@verbleif/shared";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const authStore = useAuthStore();
provideConfirmDialog();
const loaderProvider = provideLoader();
const hideNavbar = computed(
  () => route.path === "/" || route.meta.hideNavbar === true,
);
const isPublicRoute = computed(
  () => route.path === "/" || route.meta.public === true,
);
const globalLoading = ref(true);

watch(
  authStore.user,
  (newUser, oldUser) => {
    if (!newUser) {
      return;
    }

    if (oldUser?.id === newUser?.id) {
      return;
    }

    Sentry.setUser(authStore.user.value);
  },
);

if (!authStore.hasToken.value && !authStore.isRemembered.value) {
  authStore.setUser(null); // Reset it to be sure.
  globalLoading.value = false;
  loaderProvider.hide();
}

if (authStore.hasToken.value || authStore.isRemembered.value) {
  refreshToken()
    .finally(() => {
      globalLoading.value = false;
      loaderProvider.hide();
    });
}

const loading = computed(() => loaderProvider.loading.value);
</script>

<template>
  <transition name="fade">
    <Loader v-if="loading || globalLoading" />
  </transition>
  <template v-if="!globalLoading">
    <template v-if="!isPublicRoute && !hideNavbar">
      <NavbarContainer :class="{ 'is-visible': !hideNavbar }" class="navbar" />
      <!-- GLOBAL COMPONENTS THAT RELY ON A USER STATE SHOULD BE PLACED HERE -->
    </template>
    <div class="app-container" :class="{ 'is-login-route': !hideNavbar }">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="fade">
          <component :is="Component" />
        </transition>
        <transition name="fade">
          <VConfirmDialog />
        </transition>
      </router-view>
    </div>
  </template>
</template>

<style src="@verbleif/lib/src/scss/Reset.css"></style>

<style src="@verbleif/lib/src/scss/Fonts.css"></style>

<style src="@verbleif/lib/src/scss/Toast.css"></style>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: auto !important;
}

html,
body {
  letter-spacing: 0.2px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  height: 100%;
}

body {
  background: var(--background);
  --primary: theme("colors.primary.600");
  --primary-light: theme("colors.primary.400");
  --primary-lighter: theme("colors.primary.100");
  --primary-dark: theme("colors.primary.700");
  --primary-darker: theme("colors.primary.900");
  --secondary: theme("colors.secondary.600");
  --secondary-light: theme("colors.secondary.400");
  --secondary-lighter: theme("colors.secondary.100");
  --secondary-dark: theme("colors.secondary.700");
  --secondary-darker: theme("colors.secondary.900");
  --background: theme("colors.theme.light.background.primary");
  --background-dark: theme("colors.theme.light.background.secondary");
  --text: theme("colors.theme.light.text");
  --text-2: theme("colors.theme.light.text-secondary");
  --grey-text: theme("colors.grey.400");
  --text-invert: theme("colors.white");
  --invert-icon: theme("colors.grey.400");
  --grey-1: theme("colors.grey.300");
  --grey-2: theme("colors.grey.800");
  --grey-3: theme("colors.grey.400");
  --grey-3-dark: theme("colors.grey.500");
  --grey-3-darker: theme("colors.grey.600");
  --main-hover: theme("colors.grey.50");
  --white: theme("colors.white");
  --popper-shadow: theme("colors.theme.light.popper");
  --invert-text: theme("colors.theme.light.text");

  --input-background: theme("colors.white");
  --input-background-hover: theme("colors.grey.100");
  --input-background-active: theme("colors.grey.200");
  --input-text: theme("colors.grey.900");
  --input-placeholder: theme("colors.grey.400");
  --input-border: theme("colors.grey.300");
  --spacer-bg: theme("colors.grey.200");
  --background-modal: theme("colors.white");

  --tag-background: theme("colors.grey.300");
  --tag-primary-text: theme("colors.primary.600");
  --tag-primary-background: theme("colors.primary.50");
  --tag-secondary-text: theme("colors.secondary.600");
  --tag-secondary-background: theme("colors.secondary.50");
  --tag-info-text: theme("colors.blue.600");
  --tag-info-background: theme("colors.blue.50");

  --badge-hover: theme("colors.primary.600");

  .dp__theme_light {
    --dp-primary-color: var(--primary);
    --dp-success-color: var(--primary);
    --dp-success-color-disabled: var(--primary-light);
    --dp-danger-color: theme("colors.danger.600");
  }
}

body[data-theme="dark"] {
  --background: theme("colors.dark.950");
  --background-dark: theme("colors.dark.900");
  --text: theme("colors.white");
  --text-2: theme("colors.dark.200");
  --text-invert: theme("colors.white");
  --invert-icon: theme("colors.grey.100");
  --grey-1: theme("colors.dark.700");
  --grey-2: theme("colors.dark.200");
  --grey-3: theme("colors.dark.600");
  --grey-3-dark: theme("colors.dark.200");
  --grey-3-darker: theme("colors.dark.100");
  --white: theme("colors.dark.800");
  --popper-shadow: theme("colors.theme.dark.popper");
  --invert-text: theme("colors.grey.700");

  --input-background: theme("colors.dark.800");
  --input-background-hover: theme("colors.dark.700");
  --input-background-active: theme("colors.dark.600");
  --input-text: theme("colors.dark.200");
  --input-border: theme("colors.dark.600");
  --input-placeholder: theme("colors.dark.400");
  --spacer-bg: theme("colors.dark.900");
  --background-modal: theme("colors.dark.950");

  --tag-background: theme("colors.dark.500");
  --tag-primary-text: theme("colors.white");
  --tag-primary-background: theme("colors.primary.600");
  --tag-primary-hover: theme("colors.primary.400");
  --tag-secondary-text: theme("colors.white");
  --tag-secondary-background: theme("colors.secondary.600");
  --tag-info-text: theme("colors.white");
  --tag-info-background: theme("colors.blue.600");

  --badge-hover: transparent;

  .theme-switch {
    .icon {
      transform: rotate(-180deg);
    }
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

mark {
  background-color: transparent;
  color: var(--primary);
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--grey-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey-2);
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 100ms ease;
}

.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(-394px);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 100ms ease;
}

.slide-down-enter-from {
  transform: translateY(-100px);
}

.navbar {
  &.is-visible {
    animation: slide-in 250ms forwards;
  }
}

.v-container {
  padding: 20px;
}

#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: auto;

  .app-container {
    width: 100%;
    height: 100%;

    &.is-login-route {
      padding-left: 90px;
    }
  }
}

.Vue-Toastification__container.bottom-right {
  margin-bottom: 65px;
}

.dialog {
  .modal-card {
    background-color: white;
    border-radius: 2px;
    padding: 20px;

    .modal-card-head,
    .modal-card-foot {
      display: flex;
      justify-content: center;
      border: none;
      background-color: unset;
      padding: 0;

      p {
        text-align: center;
      }
    }

    .modal-card-body {
      background-color: unset;
      padding: 15px 10px;

      p {
        text-align: center;
      }
    }

    .modal-card-foot {
      display: flex;
      justify-content: center;
      padding: 0;
    }

    .modal-card-head {
      p {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
