export function useFeatureTransformer() {
  async function transformer(input) {
    return {
      name: input.name,
      description: input.description,
      alias: input.alias.toLocaleLowerCase(),
      type: input.type,
      active: input.active,
    };
  };

  return {
    transformer,
  };
}
