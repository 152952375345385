import i18n from "@/core/plugin/i18n";
import router from "@/core/plugin/router";
import { createApp } from "vue";
import App from "../App.vue";

const app = createApp(App);

app.use(router);
app.use(i18n);

export default app;
