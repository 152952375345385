import type { ConfirmDialogOptions } from "@verbleif/lib";
import * as Sentry from "@sentry/vue";
import { useConfirmDialog } from "@verbleif/lib";
import { api } from "@verbleif/shared";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

interface UseRemoveArguments {
  endpoint: string
  translationKey: string
  load?: (() => Promise<any>) | null
}

export function useRemove({
  endpoint,
  translationKey,
  load = null,
}: UseRemoveArguments) {
  const { t } = useI18n();
  const { error, success } = useToast();
  const { open, close, setLoading } = useConfirmDialog();

  function showRemove(item: Record<string, any>) {
    const name
      = typeof item.name === "object" ? item.name.join(" ") : item.name;
    const options: ConfirmDialogOptions = {
      title: t(`${translationKey}.remove_title`, { name }),
      shouldConfirm: true,
      confirmIcon: ["fas", "keyboard"],
      confirmPlaceholder: t(`${translationKey}.remove_confirm_placeholder`),
      confirmMatch: t(`${translationKey}.remove_confirm_match`),
      messageIcon: ["far", "trash"],
      message: t(`${translationKey}.remove_confirm_text`),
      continueIcon: ["fas", "trash"],
      continueText: t(`${translationKey}.remove_confirm_btn`),
      cancelText: t("base.cancel"),
      onContinue: () => remove(item),
    };

    open({ options });
  }

  function remove(item: Record<string, any>) {
    setLoading(true);
    api
      .delete(`${endpoint}/${item.id}`)
      .then(() => {
        success(t(`${translationKey}.remove_success`));
      })
      .catch((e) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }));
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        if (typeof load === "function") {
          load(); // Call load if it's a function
        }
        setLoading(false);
        close();
      });
  }

  return {
    showRemove,
  };
}
export default useRemove;
