var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
/**
 *  Copyright 2024 Verbleif
 *  @license MIT
**/
var _a, _b, _c, _d, _e, _f;
import { iriToId } from "@verbleif/lib";
import { createGlobalState, useStorage, StorageSerializers, useSessionStorage, useLocalStorage } from "@vueuse/core";
import { ref, computed, watch, watchEffect } from "vue";
import axios, { AxiosError } from "axios";
const SDK_VERSION = "8.42.0";
const GLOBAL_OBJ = globalThis;
function getGlobalSingleton(name, creator, obj) {
  const gbl = GLOBAL_OBJ;
  const __SENTRY__ = gbl.__SENTRY__ = gbl.__SENTRY__ || {};
  const versionedCarrier = __SENTRY__[SDK_VERSION] = __SENTRY__[SDK_VERSION] || {};
  return versionedCarrier[name] || (versionedCarrier[name] = creator());
}
const DEBUG_BUILD = typeof __SENTRY_DEBUG__ === "undefined" || __SENTRY_DEBUG__;
const PREFIX = "Sentry Logger ";
const CONSOLE_LEVELS = [
  "debug",
  "info",
  "warn",
  "error",
  "log",
  "assert",
  "trace"
];
const originalConsoleMethods = {};
function consoleSandbox(callback) {
  if (!("console" in GLOBAL_OBJ)) {
    return callback();
  }
  const console2 = GLOBAL_OBJ.console;
  const wrappedFuncs = {};
  const wrappedLevels = Object.keys(originalConsoleMethods);
  wrappedLevels.forEach((level2) => {
    const originalConsoleMethod = originalConsoleMethods[level2];
    wrappedFuncs[level2] = console2[level2];
    console2[level2] = originalConsoleMethod;
  });
  try {
    return callback();
  } finally {
    wrappedLevels.forEach((level2) => {
      console2[level2] = wrappedFuncs[level2];
    });
  }
}
function makeLogger() {
  let enabled = false;
  const logger2 = {
    enable: () => {
      enabled = true;
    },
    disable: () => {
      enabled = false;
    },
    isEnabled: () => enabled
  };
  if (DEBUG_BUILD) {
    CONSOLE_LEVELS.forEach((name) => {
      logger2[name] = (...args) => {
        if (enabled) {
          consoleSandbox(() => {
            GLOBAL_OBJ.console[name](`${PREFIX}[${name}]:`, ...args);
          });
        }
      };
    });
  } else {
    CONSOLE_LEVELS.forEach((name) => {
      logger2[name] = () => void 0;
    });
  }
  return logger2;
}
const logger = getGlobalSingleton("logger", makeLogger);
function getMainCarrier() {
  getSentryCarrier(GLOBAL_OBJ);
  return GLOBAL_OBJ;
}
function getSentryCarrier(carrier) {
  const __SENTRY__ = carrier.__SENTRY__ = carrier.__SENTRY__ || {};
  __SENTRY__.version = __SENTRY__.version || SDK_VERSION;
  return __SENTRY__[SDK_VERSION] = __SENTRY__[SDK_VERSION] || {};
}
const objectToString = Object.prototype.toString;
function isBuiltin(wat, className) {
  return objectToString.call(wat) === `[object ${className}]`;
}
function isPlainObject$1(wat) {
  return isBuiltin(wat, "Object");
}
function isThenable(wat) {
  return Boolean(wat && wat.then && typeof wat.then === "function");
}
function addNonEnumerableProperty(obj, name, value) {
  try {
    Object.defineProperty(obj, name, {
      // enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
      value,
      writable: true,
      configurable: true
    });
  } catch (o_O) {
    DEBUG_BUILD && logger.log(`Failed to add non-enumerable property "${name}" to object`, obj);
  }
}
const ONE_SECOND_IN_MS = 1e3;
function dateTimestampInSeconds() {
  return Date.now() / ONE_SECOND_IN_MS;
}
function createUnixTimestampInSecondsFunc() {
  const { performance } = GLOBAL_OBJ;
  if (!performance || !performance.now) {
    return dateTimestampInSeconds;
  }
  const approxStartingTimeOrigin = Date.now() - performance.now();
  const timeOrigin = performance.timeOrigin == void 0 ? approxStartingTimeOrigin : performance.timeOrigin;
  return () => {
    return (timeOrigin + performance.now()) / ONE_SECOND_IN_MS;
  };
}
const timestampInSeconds = createUnixTimestampInSecondsFunc();
(() => {
  const { performance } = GLOBAL_OBJ;
  if (!performance || !performance.now) {
    return void 0;
  }
  const threshold = 3600 * 1e3;
  const performanceNow = performance.now();
  const dateNow = Date.now();
  const timeOriginDelta = performance.timeOrigin ? Math.abs(performance.timeOrigin + performanceNow - dateNow) : threshold;
  const timeOriginIsReliable = timeOriginDelta < threshold;
  const navigationStart = performance.timing && performance.timing.navigationStart;
  const hasNavigationStart = typeof navigationStart === "number";
  const navigationStartDelta = hasNavigationStart ? Math.abs(navigationStart + performanceNow - dateNow) : threshold;
  const navigationStartIsReliable = navigationStartDelta < threshold;
  if (timeOriginIsReliable || navigationStartIsReliable) {
    if (timeOriginDelta <= navigationStartDelta) {
      return performance.timeOrigin;
    } else {
      return navigationStart;
    }
  }
  return dateNow;
})();
function uuid4() {
  const gbl = GLOBAL_OBJ;
  const crypto2 = gbl.crypto || gbl.msCrypto;
  let getRandomByte = () => Math.random() * 16;
  try {
    if (crypto2 && crypto2.randomUUID) {
      return crypto2.randomUUID().replace(/-/g, "");
    }
    if (crypto2 && crypto2.getRandomValues) {
      getRandomByte = () => {
        const typedArray = new Uint8Array(1);
        crypto2.getRandomValues(typedArray);
        return typedArray[0];
      };
    }
  } catch (_) {
  }
  return ("10000000100040008000" + 1e11).replace(
    /[018]/g,
    (c) => (
      // eslint-disable-next-line no-bitwise
      (c ^ (getRandomByte() & 15) >> c / 4).toString(16)
    )
  );
}
function updateSession(session, context = {}) {
  if (context.user) {
    if (!session.ipAddress && context.user.ip_address) {
      session.ipAddress = context.user.ip_address;
    }
    if (!session.did && !context.did) {
      session.did = context.user.id || context.user.email || context.user.username;
    }
  }
  session.timestamp = context.timestamp || timestampInSeconds();
  if (context.abnormal_mechanism) {
    session.abnormal_mechanism = context.abnormal_mechanism;
  }
  if (context.ignoreDuration) {
    session.ignoreDuration = context.ignoreDuration;
  }
  if (context.sid) {
    session.sid = context.sid.length === 32 ? context.sid : uuid4();
  }
  if (context.init !== void 0) {
    session.init = context.init;
  }
  if (!session.did && context.did) {
    session.did = `${context.did}`;
  }
  if (typeof context.started === "number") {
    session.started = context.started;
  }
  if (session.ignoreDuration) {
    session.duration = void 0;
  } else if (typeof context.duration === "number") {
    session.duration = context.duration;
  } else {
    const duration = session.timestamp - session.started;
    session.duration = duration >= 0 ? duration : 0;
  }
  if (context.release) {
    session.release = context.release;
  }
  if (context.environment) {
    session.environment = context.environment;
  }
  if (!session.ipAddress && context.ipAddress) {
    session.ipAddress = context.ipAddress;
  }
  if (!session.userAgent && context.userAgent) {
    session.userAgent = context.userAgent;
  }
  if (typeof context.errors === "number") {
    session.errors = context.errors;
  }
  if (context.status) {
    session.status = context.status;
  }
}
function generateTraceId() {
  return uuid4();
}
function generateSpanId() {
  return uuid4().substring(16);
}
function merge(initialObj, mergeObj, levels = 2) {
  if (!mergeObj || typeof mergeObj !== "object" || levels <= 0) {
    return mergeObj;
  }
  if (initialObj && mergeObj && Object.keys(mergeObj).length === 0) {
    return initialObj;
  }
  const output = { ...initialObj };
  for (const key in mergeObj) {
    if (Object.prototype.hasOwnProperty.call(mergeObj, key)) {
      output[key] = merge(output[key], mergeObj[key], levels - 1);
    }
  }
  return output;
}
const SCOPE_SPAN_FIELD = "_sentrySpan";
function _setSpanForScope(scope, span) {
  if (span) {
    addNonEnumerableProperty(scope, SCOPE_SPAN_FIELD, span);
  } else {
    delete scope[SCOPE_SPAN_FIELD];
  }
}
function _getSpanForScope(scope) {
  return scope[SCOPE_SPAN_FIELD];
}
const DEFAULT_MAX_BREADCRUMBS = 100;
class ScopeClass {
  /** Flag if notifying is happening. */
  /** Callback for client to receive scope changes. */
  /** Callback list that will be called during event processing. */
  /** Array of breadcrumbs. */
  /** User */
  /** Tags */
  /** Extra */
  /** Contexts */
  /** Attachments */
  /** Propagation Context for distributed tracing */
  /**
   * A place to stash data which is needed at some point in the SDK's event processing pipeline but which shouldn't get
   * sent to Sentry
   */
  /** Fingerprint */
  /** Severity */
  /**
   * Transaction Name
   *
   * IMPORTANT: The transaction name on the scope has nothing to do with root spans/transaction objects.
   * It's purpose is to assign a transaction to the scope that's added to non-transaction events.
   */
  /** Session */
  /** Request Mode Session Status */
  /** The client on this scope */
  /** Contains the last event id of a captured event.  */
  // NOTE: Any field which gets added here should get added not only to the constructor but also to the `clone` method.
  constructor() {
    this._notifyingListeners = false;
    this._scopeListeners = [];
    this._eventProcessors = [];
    this._breadcrumbs = [];
    this._attachments = [];
    this._user = {};
    this._tags = {};
    this._extra = {};
    this._contexts = {};
    this._sdkProcessingMetadata = {};
    this._propagationContext = {
      traceId: generateTraceId(),
      spanId: generateSpanId()
    };
  }
  /**
   * @inheritDoc
   */
  clone() {
    const newScope = new ScopeClass();
    newScope._breadcrumbs = [...this._breadcrumbs];
    newScope._tags = { ...this._tags };
    newScope._extra = { ...this._extra };
    newScope._contexts = { ...this._contexts };
    newScope._user = this._user;
    newScope._level = this._level;
    newScope._session = this._session;
    newScope._transactionName = this._transactionName;
    newScope._fingerprint = this._fingerprint;
    newScope._eventProcessors = [...this._eventProcessors];
    newScope._requestSession = this._requestSession;
    newScope._attachments = [...this._attachments];
    newScope._sdkProcessingMetadata = { ...this._sdkProcessingMetadata };
    newScope._propagationContext = { ...this._propagationContext };
    newScope._client = this._client;
    newScope._lastEventId = this._lastEventId;
    _setSpanForScope(newScope, _getSpanForScope(this));
    return newScope;
  }
  /**
   * @inheritDoc
   */
  setClient(client) {
    this._client = client;
  }
  /**
   * @inheritDoc
   */
  setLastEventId(lastEventId) {
    this._lastEventId = lastEventId;
  }
  /**
   * @inheritDoc
   */
  getClient() {
    return this._client;
  }
  /**
   * @inheritDoc
   */
  lastEventId() {
    return this._lastEventId;
  }
  /**
   * @inheritDoc
   */
  addScopeListener(callback) {
    this._scopeListeners.push(callback);
  }
  /**
   * @inheritDoc
   */
  addEventProcessor(callback) {
    this._eventProcessors.push(callback);
    return this;
  }
  /**
   * @inheritDoc
   */
  setUser(user) {
    this._user = user || {
      email: void 0,
      id: void 0,
      ip_address: void 0,
      username: void 0
    };
    if (this._session) {
      updateSession(this._session, { user });
    }
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  getUser() {
    return this._user;
  }
  /**
   * @inheritDoc
   */
  getRequestSession() {
    return this._requestSession;
  }
  /**
   * @inheritDoc
   */
  setRequestSession(requestSession) {
    this._requestSession = requestSession;
    return this;
  }
  /**
   * @inheritDoc
   */
  setTags(tags) {
    this._tags = {
      ...this._tags,
      ...tags
    };
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setTag(key, value) {
    this._tags = { ...this._tags, [key]: value };
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setExtras(extras) {
    this._extra = {
      ...this._extra,
      ...extras
    };
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setExtra(key, extra) {
    this._extra = { ...this._extra, [key]: extra };
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setFingerprint(fingerprint) {
    this._fingerprint = fingerprint;
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setLevel(level2) {
    this._level = level2;
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setTransactionName(name) {
    this._transactionName = name;
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setContext(key, context) {
    if (context === null) {
      delete this._contexts[key];
    } else {
      this._contexts[key] = context;
    }
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  setSession(session) {
    if (!session) {
      delete this._session;
    } else {
      this._session = session;
    }
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  getSession() {
    return this._session;
  }
  /**
   * @inheritDoc
   */
  update(captureContext) {
    if (!captureContext) {
      return this;
    }
    const scopeToMerge = typeof captureContext === "function" ? captureContext(this) : captureContext;
    const [scopeInstance, requestSession] = scopeToMerge instanceof Scope ? [scopeToMerge.getScopeData(), scopeToMerge.getRequestSession()] : isPlainObject$1(scopeToMerge) ? [captureContext, captureContext.requestSession] : [];
    const { tags, extra, user, contexts, level: level2, fingerprint = [], propagationContext } = scopeInstance || {};
    this._tags = { ...this._tags, ...tags };
    this._extra = { ...this._extra, ...extra };
    this._contexts = { ...this._contexts, ...contexts };
    if (user && Object.keys(user).length) {
      this._user = user;
    }
    if (level2) {
      this._level = level2;
    }
    if (fingerprint.length) {
      this._fingerprint = fingerprint;
    }
    if (propagationContext) {
      this._propagationContext = propagationContext;
    }
    if (requestSession) {
      this._requestSession = requestSession;
    }
    return this;
  }
  /**
   * @inheritDoc
   */
  clear() {
    this._breadcrumbs = [];
    this._tags = {};
    this._extra = {};
    this._user = {};
    this._contexts = {};
    this._level = void 0;
    this._transactionName = void 0;
    this._fingerprint = void 0;
    this._requestSession = void 0;
    this._session = void 0;
    _setSpanForScope(this, void 0);
    this._attachments = [];
    this.setPropagationContext({ traceId: generateTraceId() });
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(breadcrumb, maxBreadcrumbs) {
    const maxCrumbs = typeof maxBreadcrumbs === "number" ? maxBreadcrumbs : DEFAULT_MAX_BREADCRUMBS;
    if (maxCrumbs <= 0) {
      return this;
    }
    const mergedBreadcrumb = {
      timestamp: dateTimestampInSeconds(),
      ...breadcrumb
    };
    const breadcrumbs = this._breadcrumbs;
    breadcrumbs.push(mergedBreadcrumb);
    this._breadcrumbs = breadcrumbs.length > maxCrumbs ? breadcrumbs.slice(-maxCrumbs) : breadcrumbs;
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  getLastBreadcrumb() {
    return this._breadcrumbs[this._breadcrumbs.length - 1];
  }
  /**
   * @inheritDoc
   */
  clearBreadcrumbs() {
    this._breadcrumbs = [];
    this._notifyScopeListeners();
    return this;
  }
  /**
   * @inheritDoc
   */
  addAttachment(attachment) {
    this._attachments.push(attachment);
    return this;
  }
  /**
   * @inheritDoc
   */
  clearAttachments() {
    this._attachments = [];
    return this;
  }
  /** @inheritDoc */
  getScopeData() {
    return {
      breadcrumbs: this._breadcrumbs,
      attachments: this._attachments,
      contexts: this._contexts,
      tags: this._tags,
      extra: this._extra,
      user: this._user,
      level: this._level,
      fingerprint: this._fingerprint || [],
      eventProcessors: this._eventProcessors,
      propagationContext: this._propagationContext,
      sdkProcessingMetadata: this._sdkProcessingMetadata,
      transactionName: this._transactionName,
      span: _getSpanForScope(this)
    };
  }
  /**
   * @inheritDoc
   */
  setSDKProcessingMetadata(newData) {
    this._sdkProcessingMetadata = merge(this._sdkProcessingMetadata, newData, 2);
    return this;
  }
  /**
   * @inheritDoc
   */
  setPropagationContext(context) {
    this._propagationContext = {
      // eslint-disable-next-line deprecation/deprecation
      spanId: generateSpanId(),
      ...context
    };
    return this;
  }
  /**
   * @inheritDoc
   */
  getPropagationContext() {
    return this._propagationContext;
  }
  /**
   * @inheritDoc
   */
  captureException(exception, hint) {
    const eventId = hint && hint.event_id ? hint.event_id : uuid4();
    if (!this._client) {
      logger.warn("No client configured on scope - will not capture exception!");
      return eventId;
    }
    const syntheticException = new Error("Sentry syntheticException");
    this._client.captureException(
      exception,
      {
        originalException: exception,
        syntheticException,
        ...hint,
        event_id: eventId
      },
      this
    );
    return eventId;
  }
  /**
   * @inheritDoc
   */
  captureMessage(message, level2, hint) {
    const eventId = hint && hint.event_id ? hint.event_id : uuid4();
    if (!this._client) {
      logger.warn("No client configured on scope - will not capture message!");
      return eventId;
    }
    const syntheticException = new Error(message);
    this._client.captureMessage(
      message,
      level2,
      {
        originalException: message,
        syntheticException,
        ...hint,
        event_id: eventId
      },
      this
    );
    return eventId;
  }
  /**
   * @inheritDoc
   */
  captureEvent(event, hint) {
    const eventId = hint && hint.event_id ? hint.event_id : uuid4();
    if (!this._client) {
      logger.warn("No client configured on scope - will not capture event!");
      return eventId;
    }
    this._client.captureEvent(event, { ...hint, event_id: eventId }, this);
    return eventId;
  }
  /**
   * This will be called on every set call.
   */
  _notifyScopeListeners() {
    if (!this._notifyingListeners) {
      this._notifyingListeners = true;
      this._scopeListeners.forEach((callback) => {
        callback(this);
      });
      this._notifyingListeners = false;
    }
  }
}
const Scope = ScopeClass;
function getDefaultCurrentScope() {
  return getGlobalSingleton("defaultCurrentScope", () => new Scope());
}
function getDefaultIsolationScope() {
  return getGlobalSingleton("defaultIsolationScope", () => new Scope());
}
class AsyncContextStack {
  constructor(scope, isolationScope) {
    let assignedScope;
    if (!scope) {
      assignedScope = new Scope();
    } else {
      assignedScope = scope;
    }
    let assignedIsolationScope;
    if (!isolationScope) {
      assignedIsolationScope = new Scope();
    } else {
      assignedIsolationScope = isolationScope;
    }
    this._stack = [{ scope: assignedScope }];
    this._isolationScope = assignedIsolationScope;
  }
  /**
   * Fork a scope for the stack.
   */
  withScope(callback) {
    const scope = this._pushScope();
    let maybePromiseResult;
    try {
      maybePromiseResult = callback(scope);
    } catch (e) {
      this._popScope();
      throw e;
    }
    if (isThenable(maybePromiseResult)) {
      return maybePromiseResult.then(
        (res) => {
          this._popScope();
          return res;
        },
        (e) => {
          this._popScope();
          throw e;
        }
      );
    }
    this._popScope();
    return maybePromiseResult;
  }
  /**
   * Get the client of the stack.
   */
  getClient() {
    return this.getStackTop().client;
  }
  /**
   * Returns the scope of the top stack.
   */
  getScope() {
    return this.getStackTop().scope;
  }
  /**
   * Get the isolation scope for the stack.
   */
  getIsolationScope() {
    return this._isolationScope;
  }
  /**
   * Returns the topmost scope layer in the order domain > local > process.
   */
  getStackTop() {
    return this._stack[this._stack.length - 1];
  }
  /**
   * Push a scope to the stack.
   */
  _pushScope() {
    const scope = this.getScope().clone();
    this._stack.push({
      client: this.getClient(),
      scope
    });
    return scope;
  }
  /**
   * Pop a scope from the stack.
   */
  _popScope() {
    if (this._stack.length <= 1) return false;
    return !!this._stack.pop();
  }
}
function getAsyncContextStack() {
  const registry = getMainCarrier();
  const sentry = getSentryCarrier(registry);
  return sentry.stack = sentry.stack || new AsyncContextStack(getDefaultCurrentScope(), getDefaultIsolationScope());
}
function withScope(callback) {
  return getAsyncContextStack().withScope(callback);
}
function withSetScope(scope, callback) {
  const stack = getAsyncContextStack();
  return stack.withScope(() => {
    stack.getStackTop().scope = scope;
    return callback(scope);
  });
}
function withIsolationScope(callback) {
  return getAsyncContextStack().withScope(() => {
    return callback(getAsyncContextStack().getIsolationScope());
  });
}
function getStackAsyncContextStrategy() {
  return {
    withIsolationScope,
    withScope,
    withSetScope,
    withSetIsolationScope: (_isolationScope, callback) => {
      return withIsolationScope(callback);
    },
    getCurrentScope: () => getAsyncContextStack().getScope(),
    getIsolationScope: () => getAsyncContextStack().getIsolationScope()
  };
}
function getAsyncContextStrategy(carrier) {
  const sentry = getSentryCarrier(carrier);
  if (sentry.acs) {
    return sentry.acs;
  }
  return getStackAsyncContextStrategy();
}
function getIsolationScope() {
  const carrier = getMainCarrier();
  const acs = getAsyncContextStrategy(carrier);
  return acs.getIsolationScope();
}
function setUser(user) {
  getIsolationScope().setUser(user);
}
const useAuthStore = createGlobalState(() => {
  const token = useStorage("token", null, sessionStorage, {
    serializer: StorageSerializers.string
  });
  const returnUrl = useStorage("return-url", null, void 0, {
    serializer: StorageSerializers.string
  });
  const refreshToken = useStorage(
    "refresh_token",
    null,
    sessionStorage,
    { serializer: StorageSerializers.string }
  );
  const refreshTokenRemembered = useStorage(
    "refresh-token",
    null,
    void 0,
    { serializer: StorageSerializers.string }
  );
  const user = ref(null);
  let userPermissions;
  let permissions;
  {
    permissions = useStorage(
      "permissions",
      null,
      void 0,
      { serializer: StorageSerializers.object }
    );
    userPermissions = useStorage(
      "user-permissions",
      []
    );
  }
  const userPreferences = useStorage(
    "user-preferences",
    {
      locale: "NL",
      timezone: "Europe/Amsterdam"
    },
    void 0,
    { serializer: StorageSerializers.object }
  );
  function setUserPreferences(user2) {
    if (user2 === null) {
      return;
    }
    if (user2.locale) {
      userPreferences.value.locale = user2.locale.toUpperCase();
    }
    if (user2.timezone) {
      userPreferences.value.timezone = user2.timezone;
    }
  }
  const isAuthenticated = computed(() => {
    return user.value !== null;
  });
  const isRemembered = computed(() => {
    return refreshTokenRemembered.value !== null;
  });
  const hasToken = computed(() => {
    return token.value !== null;
  });
  const getRefreshToken = computed(() => {
    return refreshToken.value ? refreshToken.value : refreshTokenRemembered.value;
  });
  const getRequestHeaders = computed(() => {
    return {
      Authorization: `Bearer ${token.value}`
    };
  });
  const tokenPayload = computed(
    () => {
      if (token.value === null) {
        return null;
      }
      const base64Url = token.value.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64).split("").map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        }).join("")
      );
      const parsed = JSON.parse(jsonPayload);
      if (!parsed || typeof parsed !== "object") {
        return null;
      }
      return parsed;
    }
  );
  function setRefreshToken(refresh, remembered = isRemembered.value) {
    console.log("[Triggered setRefreshToken]", refresh, remembered);
    if (!refresh) {
      refreshToken.value = null;
      refreshTokenRemembered.value = null;
      return;
    }
    refreshToken.value = refresh;
    if (remembered) {
      refreshTokenRemembered.value = refreshToken.value;
    }
  }
  function setToken(t) {
    token.value = t;
  }
  function setUser$1(userInput) {
    user.value = {
      ...userInput,
      "@id": `/api/users/${userInput == null ? void 0 : userInput.id}`
    };
    setUserPreferences(userInput);
  }
  function patchUser(us) {
    if (!user.value) {
      return;
    }
    Object.assign(user.value, us);
  }
  async function initUser() {
    const { data: user2 } = await api.get("/api/users/me");
    setUser$1(user2);
    {
      setUser(user2);
    }
  }
  async function initPermissions() {
    var _a2, _b2;
    {
      const userIri = (_a2 = user.value) == null ? void 0 : _a2["@id"];
      if (!userIri) {
        return;
      }
      permissions.value = {};
      userPermissions.value = [];
      if ((_b2 = user.value) == null ? void 0 : _b2.permissions) {
        for (const permission of user.value.permissions) {
          if (!permissions.value) {
            return;
          }
          userPermissions.value = permission.attributes;
        }
      }
    }
  }
  function hasRole(role) {
    var _a2;
    return ((_a2 = user.value) == null ? void 0 : _a2.roles.includes(role)) ?? false;
  }
  function reset() {
    token.value = null;
    refreshToken.value = null;
    refreshTokenRemembered.value = null;
    user.value = null;
    returnUrl.value = null;
    permissions.value = null;
    userPermissions.value = [];
  }
  return {
    token,
    returnUrl,
    refreshToken,
    refreshTokenRemembered,
    user,
    userPermissions,
    permissions,
    userPreferences,
    setUserPreferences,
    isAuthenticated,
    isRemembered,
    hasToken,
    getRefreshToken,
    getRequestHeaders,
    tokenPayload,
    setToken,
    setRefreshToken,
    setUser: setUser$1,
    patchUser,
    initUser,
    initPermissions,
    hasRole,
    reset
  };
});
function createConfigStore() {
  const initialConfig = ref(null);
  const debugMode = useStorage("debugMode", false);
  const overrideConfig = useSessionStorage(
    "overrideConfig",
    null,
    {
      serializer: {
        read: (v) => v ? JSON.parse(v) : null,
        write: (v) => JSON.stringify(v)
      }
    }
  );
  function setInitialConfig(newConfig) {
    initialConfig.value = newConfig;
  }
  function setOverrideConfig(newConfig) {
    overrideConfig.value = newConfig;
  }
  function reset() {
    overrideConfig.value = null;
  }
  const config = computed(() => {
    if (overrideConfig.value) {
      return overrideConfig.value;
    }
    return initialConfig.value;
  });
  return {
    config,
    overrideConfig,
    setOverrideConfig,
    debugMode,
    setInitialConfig,
    reset
  };
}
let backwardsCompatibleInstance$1 = null;
function useConfigStore() {
  if (backwardsCompatibleInstance$1 === null) {
    backwardsCompatibleInstance$1 = createConfigStore();
  }
  return backwardsCompatibleInstance$1;
}
function AcceptLanguageRequestInterceptor(localeGetter, excludedRoutes = []) {
  async function resolved(config) {
    if (!config.url) {
      return config;
    }
    if (excludedRoutes.includes(config.url)) {
      return config;
    }
    const locale = localeGetter();
    if (locale) {
      config.headers["Accept-Language"] = localeGetter();
    }
    return config;
  }
  return {
    name: "AcceptHeaderRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved
  };
}
var AuthPath = /* @__PURE__ */ ((AuthPath2) => {
  AuthPath2["OAUTH_REFRESH_V2"] = "/oauth/v2/token";
  return AuthPath2;
})(AuthPath || {});
function createAuthenticatePlugin(config) {
  const currentPromise = {
    [
      "/oauth/v2/token"
      /* OAUTH_REFRESH_V2 */
    ]: null
  };
  function clearCurrentPromise() {
    currentPromise[config.path] = null;
  }
  async function authenticate(input) {
    let promise = currentPromise[config.path];
    console.log("promise", promise);
    if (!promise) {
      promise = config.axios.post(config.path, input).then(async (r) => {
        if (config.onAuthenticateSuccess) {
          await config.onAuthenticateSuccess(r.data);
        }
        return r.data;
      }).catch(async (e) => {
        if (config.onAuthenticateFailed) {
          await config.onAuthenticateFailed(e);
        }
        throw e;
      }).finally(clearCurrentPromise);
      currentPromise[config.path] = promise;
    }
    await promise;
    return promise;
  }
  return { authenticate };
}
function BaseUrlRequestInterceptor(baseUrlGetter) {
  async function resolved(requestConfig) {
    requestConfig.baseURL = baseUrlGetter(requestConfig);
    return requestConfig;
  }
  return {
    name: "BaseUrlRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 1,
    resolved
  };
}
function contentTypeInterceptor({
  excludedRoutes
} = {}) {
  async function resolved(config) {
    var _a2, _b2, _c2;
    if (!config.url) {
      return config;
    }
    if (excludedRoutes && excludedRoutes.includes(config.url)) {
      return config;
    }
    config.headers = config.headers || {};
    if ((_a2 = config.method) == null ? void 0 : _a2.toLowerCase()) {
      if (((_b2 = config.method) == null ? void 0 : _b2.toLowerCase()) === "patch") {
        config.headers["Content-Type"] = "application/merge-patch+json";
      }
      if (["post", "put"].includes((_c2 = config.method) == null ? void 0 : _c2.toLowerCase())) {
        config.headers["Content-Type"] = "application/ld+json";
      }
    }
    return config;
  }
  return {
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved
  };
}
class AbortedError extends AxiosError {
  constructor(message = "A timeout error occurred.") {
    super(message);
    this.message = message;
    this.name = "AbortedError";
    Object.defineProperty(this, "isAxiosError", {
      value: true,
      writable: false,
      enumerable: false,
      configurable: false
    });
    if ("captureStackTrace" in Error && typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, AbortedError);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
class ClientError extends AxiosError {
  constructor(config, request, response, message = "base.api.client_error", code = "0") {
    super(message, code, config, request, response);
    __publicField(this, "status");
    __publicField(this, "config");
    __publicField(this, "request");
    __publicField(this, "response");
    this.message = message;
    this.name = "ClientError";
    this.status = response.status;
    this.config = config;
    this.request = request;
    this.response = response;
    if ("captureStackTrace" in Error) {
      Error.captureStackTrace(this, ClientError);
    }
  }
}
class NetworkError extends AxiosError {
  constructor(message = "base.api.network_error") {
    super(message);
    this.message = message;
    this.name = "NetworkError";
    if ("captureStackTrace" in Error) {
      Error.captureStackTrace(this, NetworkError);
    }
  }
}
class ServerError extends AxiosError {
  constructor(config, request, response, message = "base.api.server_error", code = "0") {
    super(message, code, config, request, response);
    __publicField(this, "status");
    __publicField(this, "config");
    __publicField(this, "request");
    __publicField(this, "response");
    this.message = message;
    this.name = "ServerError";
    this.status = response.status;
    this.config = config;
    this.request = request;
    this.response = response;
    if ("captureStackTrace" in Error) {
      Error.captureStackTrace(this, ServerError);
    }
  }
}
function ErrorHandlerResponseInterceptor() {
  async function resolved(response) {
    if (response.status >= 400 && response.status < 499) {
      throw new ClientError(response.config, response.request, response);
    }
    if (response.status >= 500 && response.status < 599) {
      throw new ServerError(response.config, response.request, response);
    }
    return response;
  }
  async function rejected(error) {
    if (!(error == null ? void 0 : error.config)) {
      throw error;
    }
    if ((error == null ? void 0 : error.code) === "ERR_CANCELED") {
      console.log("Request aborted by user");
      throw new AbortedError();
    }
    if (!(error == null ? void 0 : error.response)) {
      throw new NetworkError();
    }
    if (error.response.status >= 400 && error.response.status < 499) {
      throw new ClientError(error.config, error.request, error.response);
    }
    if (error.response.status >= 500 && error.response.status < 599) {
      throw new ServerError(error.config, error.request, error.response);
    }
    return error;
  }
  return {
    name: "ErrorHandlerResponseInterceptor",
    type: InterceptorType.RESPONSE,
    priority: -1e3,
    rejected,
    resolved
  };
}
function HeaderRequestInterceptor(headers) {
  async function resolved(requestConfig) {
    Object.assign(requestConfig.headers, headers());
    return requestConfig;
  }
  return {
    name: "HeaderRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 1e3,
    resolved
  };
}
function JwtBearerTokenRequestInterceptor(tokenGetter, excludedRoutes = []) {
  async function resolved(config) {
    if (!config.url) {
      return config;
    }
    if (excludedRoutes.includes(config.url)) {
      return config;
    }
    config.headers.Authorization = `Bearer ${tokenGetter()}`;
    return config;
  }
  return {
    name: "JwtBearerTokenRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved
  };
}
function LegacyUrlPrefix() {
  async function resolved(config) {
    if (!config.url) {
      return config;
    }
    if (config.url.startsWith("/api") || config.url.includes("/oauth/v2")) {
      return config;
    }
    config.url = `/api${config.url}`;
    return config;
  }
  return {
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved
  };
}
var HttpStatusCode = /* @__PURE__ */ ((HttpStatusCode2) => {
  HttpStatusCode2[HttpStatusCode2["HTTP_CONTINUE"] = 100] = "HTTP_CONTINUE";
  HttpStatusCode2[HttpStatusCode2["HTTP_SWITCHING_PROTOCOLS"] = 101] = "HTTP_SWITCHING_PROTOCOLS";
  HttpStatusCode2[HttpStatusCode2["HTTP_PROCESSING"] = 102] = "HTTP_PROCESSING";
  HttpStatusCode2[HttpStatusCode2["HTTP_EARLY_HINTS"] = 103] = "HTTP_EARLY_HINTS";
  HttpStatusCode2[HttpStatusCode2["HTTP_OK"] = 200] = "HTTP_OK";
  HttpStatusCode2[HttpStatusCode2["HTTP_CREATED"] = 201] = "HTTP_CREATED";
  HttpStatusCode2[HttpStatusCode2["HTTP_ACCEPTED"] = 202] = "HTTP_ACCEPTED";
  HttpStatusCode2[HttpStatusCode2["HTTP_NON_AUTHORITATIVE_INFORMATION"] = 203] = "HTTP_NON_AUTHORITATIVE_INFORMATION";
  HttpStatusCode2[HttpStatusCode2["HTTP_NO_CONTENT"] = 204] = "HTTP_NO_CONTENT";
  HttpStatusCode2[HttpStatusCode2["HTTP_RESET_CONTENT"] = 205] = "HTTP_RESET_CONTENT";
  HttpStatusCode2[HttpStatusCode2["HTTP_PARTIAL_CONTENT"] = 206] = "HTTP_PARTIAL_CONTENT";
  HttpStatusCode2[HttpStatusCode2["HTTP_MULTI_STATUS"] = 207] = "HTTP_MULTI_STATUS";
  HttpStatusCode2[HttpStatusCode2["HTTP_ALREADY_REPORTED"] = 208] = "HTTP_ALREADY_REPORTED";
  HttpStatusCode2[HttpStatusCode2["HTTP_IM_USED"] = 226] = "HTTP_IM_USED";
  HttpStatusCode2[HttpStatusCode2["HTTP_MULTIPLE_CHOICES"] = 300] = "HTTP_MULTIPLE_CHOICES";
  HttpStatusCode2[HttpStatusCode2["HTTP_MOVED_PERMANENTLY"] = 301] = "HTTP_MOVED_PERMANENTLY";
  HttpStatusCode2[HttpStatusCode2["HTTP_FOUND"] = 302] = "HTTP_FOUND";
  HttpStatusCode2[HttpStatusCode2["HTTP_SEE_OTHER"] = 303] = "HTTP_SEE_OTHER";
  HttpStatusCode2[HttpStatusCode2["HTTP_NOT_MODIFIED"] = 304] = "HTTP_NOT_MODIFIED";
  HttpStatusCode2[HttpStatusCode2["HTTP_USE_PROXY"] = 305] = "HTTP_USE_PROXY";
  HttpStatusCode2[HttpStatusCode2["HTTP_RESERVED"] = 306] = "HTTP_RESERVED";
  HttpStatusCode2[HttpStatusCode2["HTTP_TEMPORARY_REDIRECT"] = 307] = "HTTP_TEMPORARY_REDIRECT";
  HttpStatusCode2[HttpStatusCode2["HTTP_PERMANENTLY_REDIRECT"] = 308] = "HTTP_PERMANENTLY_REDIRECT";
  HttpStatusCode2[HttpStatusCode2["HTTP_BAD_REQUEST"] = 400] = "HTTP_BAD_REQUEST";
  HttpStatusCode2[HttpStatusCode2["HTTP_UNAUTHORIZED"] = 401] = "HTTP_UNAUTHORIZED";
  HttpStatusCode2[HttpStatusCode2["HTTP_PAYMENT_REQUIRED"] = 402] = "HTTP_PAYMENT_REQUIRED";
  HttpStatusCode2[HttpStatusCode2["HTTP_FORBIDDEN"] = 403] = "HTTP_FORBIDDEN";
  HttpStatusCode2[HttpStatusCode2["HTTP_NOT_FOUND"] = 404] = "HTTP_NOT_FOUND";
  HttpStatusCode2[HttpStatusCode2["HTTP_METHOD_NOT_ALLOWED"] = 405] = "HTTP_METHOD_NOT_ALLOWED";
  HttpStatusCode2[HttpStatusCode2["HTTP_NOT_ACCEPTABLE"] = 406] = "HTTP_NOT_ACCEPTABLE";
  HttpStatusCode2[HttpStatusCode2["HTTP_PROXY_AUTHENTICATION_REQUIRED"] = 407] = "HTTP_PROXY_AUTHENTICATION_REQUIRED";
  HttpStatusCode2[HttpStatusCode2["HTTP_REQUEST_TIMEOUT"] = 408] = "HTTP_REQUEST_TIMEOUT";
  HttpStatusCode2[HttpStatusCode2["HTTP_CONFLICT"] = 409] = "HTTP_CONFLICT";
  HttpStatusCode2[HttpStatusCode2["HTTP_GONE"] = 410] = "HTTP_GONE";
  HttpStatusCode2[HttpStatusCode2["HTTP_LENGTH_REQUIRED"] = 411] = "HTTP_LENGTH_REQUIRED";
  HttpStatusCode2[HttpStatusCode2["HTTP_PRECONDITION_FAILED"] = 412] = "HTTP_PRECONDITION_FAILED";
  HttpStatusCode2[HttpStatusCode2["HTTP_REQUEST_ENTITY_TOO_LARGE"] = 413] = "HTTP_REQUEST_ENTITY_TOO_LARGE";
  HttpStatusCode2[HttpStatusCode2["HTTP_REQUEST_URI_TOO_LONG"] = 414] = "HTTP_REQUEST_URI_TOO_LONG";
  HttpStatusCode2[HttpStatusCode2["HTTP_UNSUPPORTED_MEDIA_TYPE"] = 415] = "HTTP_UNSUPPORTED_MEDIA_TYPE";
  HttpStatusCode2[HttpStatusCode2["HTTP_REQUESTED_RANGE_NOT_SATISFIABLE"] = 416] = "HTTP_REQUESTED_RANGE_NOT_SATISFIABLE";
  HttpStatusCode2[HttpStatusCode2["HTTP_EXPECTATION_FAILED"] = 417] = "HTTP_EXPECTATION_FAILED";
  HttpStatusCode2[HttpStatusCode2["HTTP_I_AM_A_TEAPOT"] = 418] = "HTTP_I_AM_A_TEAPOT";
  HttpStatusCode2[HttpStatusCode2["HTTP_MISDIRECTED_REQUEST"] = 421] = "HTTP_MISDIRECTED_REQUEST";
  HttpStatusCode2[HttpStatusCode2["HTTP_UNPROCESSABLE_ENTITY"] = 422] = "HTTP_UNPROCESSABLE_ENTITY";
  HttpStatusCode2[HttpStatusCode2["HTTP_LOCKED"] = 423] = "HTTP_LOCKED";
  HttpStatusCode2[HttpStatusCode2["HTTP_FAILED_DEPENDENCY"] = 424] = "HTTP_FAILED_DEPENDENCY";
  HttpStatusCode2[HttpStatusCode2["HTTP_TOO_EARLY"] = 425] = "HTTP_TOO_EARLY";
  HttpStatusCode2[HttpStatusCode2["HTTP_UPGRADE_REQUIRED"] = 426] = "HTTP_UPGRADE_REQUIRED";
  HttpStatusCode2[HttpStatusCode2["HTTP_PRECONDITION_REQUIRED"] = 428] = "HTTP_PRECONDITION_REQUIRED";
  HttpStatusCode2[HttpStatusCode2["HTTP_TOO_MANY_REQUESTS"] = 429] = "HTTP_TOO_MANY_REQUESTS";
  HttpStatusCode2[HttpStatusCode2["HTTP_REQUEST_HEADER_FIELDS_TOO_LARGE"] = 431] = "HTTP_REQUEST_HEADER_FIELDS_TOO_LARGE";
  HttpStatusCode2[HttpStatusCode2["HTTP_UNAVAILABLE_FOR_LEGAL_REASONS"] = 451] = "HTTP_UNAVAILABLE_FOR_LEGAL_REASONS";
  HttpStatusCode2[HttpStatusCode2["HTTP_INTERNAL_SERVER_ERROR"] = 500] = "HTTP_INTERNAL_SERVER_ERROR";
  HttpStatusCode2[HttpStatusCode2["HTTP_NOT_IMPLEMENTED"] = 501] = "HTTP_NOT_IMPLEMENTED";
  HttpStatusCode2[HttpStatusCode2["HTTP_BAD_GATEWAY"] = 502] = "HTTP_BAD_GATEWAY";
  HttpStatusCode2[HttpStatusCode2["HTTP_SERVICE_UNAVAILABLE"] = 503] = "HTTP_SERVICE_UNAVAILABLE";
  HttpStatusCode2[HttpStatusCode2["HTTP_GATEWAY_TIMEOUT"] = 504] = "HTTP_GATEWAY_TIMEOUT";
  HttpStatusCode2[HttpStatusCode2["HTTP_VERSION_NOT_SUPPORTED"] = 505] = "HTTP_VERSION_NOT_SUPPORTED";
  HttpStatusCode2[HttpStatusCode2["HTTP_VARIANT_ALSO_NEGOTIATES_EXPERIMENTAL"] = 506] = "HTTP_VARIANT_ALSO_NEGOTIATES_EXPERIMENTAL";
  HttpStatusCode2[HttpStatusCode2["HTTP_INSUFFICIENT_STORAGE"] = 507] = "HTTP_INSUFFICIENT_STORAGE";
  HttpStatusCode2[HttpStatusCode2["HTTP_LOOP_DETECTED"] = 508] = "HTTP_LOOP_DETECTED";
  HttpStatusCode2[HttpStatusCode2["HTTP_NOT_EXTENDED"] = 510] = "HTTP_NOT_EXTENDED";
  HttpStatusCode2[HttpStatusCode2["HTTP_NETWORK_AUTHENTICATION_REQUIRED"] = 511] = "HTTP_NETWORK_AUTHENTICATION_REQUIRED";
  return HttpStatusCode2;
})(HttpStatusCode || {});
function RefreshTokenResponseInterceptor(axiosInstance, refreshToken, excludedRoutes = []) {
  async function rejected(failedRequest) {
    if (!(failedRequest instanceof ClientError)) {
      throw failedRequest;
    }
    if (!failedRequest.config.url || failedRequest.response.status !== HttpStatusCode.HTTP_UNAUTHORIZED || excludedRoutes.includes(failedRequest.config.url) || failedRequest.config.retry === true) {
      throw failedRequest;
    }
    await refreshToken();
    const config = { ...failedRequest.config, retry: true };
    return axiosInstance.request(config);
  }
  return {
    name: "RefreshTokenResponseInterceptor",
    type: InterceptorType.RESPONSE,
    priority: 100,
    rejected
  };
}
var InterceptorType = /* @__PURE__ */ ((InterceptorType2) => {
  InterceptorType2["REQUEST"] = "request";
  InterceptorType2["RESPONSE"] = "response";
  return InterceptorType2;
})(InterceptorType || {});
const defaultConfig = {
  enableErrorHandler: true,
  interceptors: []
};
function createApi(config = defaultConfig) {
  const api2 = axios.create();
  const interceptors = [...config.interceptors];
  if (config.enableErrorHandler) {
    interceptors.push(ErrorHandlerResponseInterceptor());
  }
  const baseUrl = config.baseURL;
  if (baseUrl !== void 0) {
    let baseUrlGetter;
    if (typeof baseUrl === "string") {
      baseUrlGetter = () => baseUrl;
    } else {
      baseUrlGetter = baseUrl;
    }
    interceptors.push(BaseUrlRequestInterceptor(baseUrlGetter));
  }
  function installInterceptor2(p) {
    interceptors.push(p);
    installInterceptors();
  }
  function installInterceptors() {
    api2.interceptors.request.clear();
    api2.interceptors.response.clear();
    interceptors.sort((a, b) => a.priority - b.priority).forEach((config2) => {
      switch (config2.type) {
        case "request":
          api2.interceptors.request.use(config2.resolved, config2.rejected);
          break;
        case "response":
          api2.interceptors.response.use(config2.resolved, config2.rejected);
          break;
      }
    });
  }
  installInterceptors();
  return {
    api: api2,
    installInterceptor: installInterceptor2
  };
}
const { api, installInterceptor } = createApi({
  enableErrorHandler: true,
  baseURL: (requestConfig) => {
    var _a2, _b2, _c2, _d2;
    return ((_a2 = requestConfig.url) == null ? void 0 : _a2.includes("oauth/v2/token")) || ((_b2 = requestConfig.url) == null ? void 0 : _b2.includes("oauth/v2/authorize")) ? `${(_c2 = useConfigStore().config.value) == null ? void 0 : _c2.loginUrl}` : `${(_d2 = useConfigStore().config.value) == null ? void 0 : _d2.apiUrl}`;
  },
  interceptors: [
    JwtBearerTokenRequestInterceptor(
      () => useAuthStore().token.value || "",
      ["/oauth/v2/token"]
    ),
    LegacyUrlPrefix(),
    contentTypeInterceptor({
      excludedRoutes: ["/oauth/v2/token", "/oauth/v2/authorize"]
    })
  ]
});
var BaseUrl = /* @__PURE__ */ ((BaseUrl2) => {
  BaseUrl2["LOGIN"] = "https://login.verbleif.dev";
  BaseUrl2["API"] = "https://api.verbleif.dev";
  return BaseUrl2;
})(BaseUrl || {});
async function getAll({
  url,
  initialParams,
  perPage = 100,
  signal,
  _retry = true
}) {
  let items = [];
  async function fetch(page = 1) {
    if (signal == null ? void 0 : signal.aborted) {
      return;
    }
    const params = new URLSearchParams(initialParams);
    params.set("perPage", perPage.toString());
    params.set("page", page.toString());
    const data = await api.get(url, { params, signal, _retry }).then(({ data: data2 }) => data2.member);
    items = [...items, ...data];
    if (data.length === perPage) {
      await fetch(page + 1);
    }
    return items;
  }
  await fetch();
  return items;
}
const crypto = ((_a = typeof globalThis != "undefined" ? globalThis : void 0) == null ? void 0 : _a.crypto) || ((_b = typeof global != "undefined" ? global : void 0) == null ? void 0 : _b.crypto) || ((_c = typeof window != "undefined" ? window : void 0) == null ? void 0 : _c.crypto) || ((_d = typeof self != "undefined" ? self : void 0) == null ? void 0 : _d.crypto) || ((_f = (_e = typeof frames != "undefined" ? frames : void 0) == null ? void 0 : _e[0]) == null ? void 0 : _f.crypto);
let randomWordArray;
if (crypto) {
  randomWordArray = (nBytes) => {
    const words = [];
    for (let i = 0, rcache; i < nBytes; i += 4) {
      words.push(crypto.getRandomValues(new Uint32Array(1))[0]);
    }
    return new WordArray(words, nBytes);
  };
} else {
  randomWordArray = (nBytes) => {
    const words = [];
    const r = (m_w) => {
      let _m_w = m_w;
      let _m_z = 987654321;
      const mask = 4294967295;
      return () => {
        _m_z = 36969 * (_m_z & 65535) + (_m_z >> 16) & mask;
        _m_w = 18e3 * (_m_w & 65535) + (_m_w >> 16) & mask;
        let result = (_m_z << 16) + _m_w & mask;
        result /= 4294967296;
        result += 0.5;
        return result * (Math.random() > 0.5 ? 1 : -1);
      };
    };
    for (let i = 0, rcache; i < nBytes; i += 4) {
      const _r = r((rcache || Math.random()) * 4294967296);
      rcache = _r() * 987654071;
      words.push(_r() * 4294967296 | 0);
    }
    return new WordArray(words, nBytes);
  };
}
class Base {
  /**
   * Extends this object and runs the init method.
   * Arguments to create() will be passed to init().
   *
   * @return {Object} The new object.
   *
   * @static
   *
   * @example
   *
   *     var instance = MyType.create();
   */
  static create(...args) {
    return new this(...args);
  }
  /**
   * Copies properties into this object.
   *
   * @param {Object} properties The properties to mix in.
   *
   * @example
   *
   *     MyType.mixIn({
   *         field: 'value'
   *     });
   */
  mixIn(properties) {
    return Object.assign(this, properties);
  }
  /**
   * Creates a copy of this object.
   *
   * @return {Object} The clone.
   *
   * @example
   *
   *     var clone = instance.clone();
   */
  clone() {
    const clone = new this.constructor();
    Object.assign(clone, this);
    return clone;
  }
}
class WordArray extends Base {
  /**
   * Initializes a newly created word array.
   *
   * @param {Array} words (Optional) An array of 32-bit words.
   * @param {number} sigBytes (Optional) The number of significant bytes in the words.
   *
   * @example
   *
   *     var wordArray = CryptoJS.lib.WordArray.create();
   *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607]);
   *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607], 6);
   */
  constructor(words = [], sigBytes = words.length * 4) {
    super();
    let typedArray = words;
    if (typedArray instanceof ArrayBuffer) {
      typedArray = new Uint8Array(typedArray);
    }
    if (typedArray instanceof Int8Array || typedArray instanceof Uint8ClampedArray || typedArray instanceof Int16Array || typedArray instanceof Uint16Array || typedArray instanceof Int32Array || typedArray instanceof Uint32Array || typedArray instanceof Float32Array || typedArray instanceof Float64Array) {
      typedArray = new Uint8Array(typedArray.buffer, typedArray.byteOffset, typedArray.byteLength);
    }
    if (typedArray instanceof Uint8Array) {
      const typedArrayByteLength = typedArray.byteLength;
      const _words = [];
      for (let i = 0; i < typedArrayByteLength; i += 1) {
        _words[i >>> 2] |= typedArray[i] << 24 - i % 4 * 8;
      }
      this.words = _words;
      this.sigBytes = typedArrayByteLength;
    } else {
      this.words = words;
      this.sigBytes = sigBytes;
    }
  }
  /**
   * Converts this word array to a string.
   *
   * @param {Encoder} encoder (Optional) The encoding strategy to use. Default: CryptoJS.enc.Hex
   *
   * @return {string} The stringified word array.
   *
   * @example
   *
   *     var string = wordArray + '';
   *     var string = wordArray.toString();
   *     var string = wordArray.toString(CryptoJS.enc.Utf8);
   */
  toString(encoder = Hex) {
    return encoder.stringify(this);
  }
  /**
   * Concatenates a word array to this word array.
   *
   * @param {WordArray} wordArray The word array to append.
   *
   * @return {WordArray} This word array.
   *
   * @example
   *
   *     wordArray1.concat(wordArray2);
   */
  concat(wordArray) {
    const thisWords = this.words;
    const thatWords = wordArray.words;
    const thisSigBytes = this.sigBytes;
    const thatSigBytes = wordArray.sigBytes;
    this.clamp();
    if (thisSigBytes % 4) {
      for (let i = 0; i < thatSigBytes; i += 1) {
        const thatByte = thatWords[i >>> 2] >>> 24 - i % 4 * 8 & 255;
        thisWords[thisSigBytes + i >>> 2] |= thatByte << 24 - (thisSigBytes + i) % 4 * 8;
      }
    } else {
      for (let i = 0; i < thatSigBytes; i += 4) {
        thisWords[thisSigBytes + i >>> 2] = thatWords[i >>> 2];
      }
    }
    this.sigBytes += thatSigBytes;
    return this;
  }
  /**
   * Removes insignificant bits.
   *
   * @example
   *
   *     wordArray.clamp();
   */
  clamp() {
    const { words, sigBytes } = this;
    words[sigBytes >>> 2] &= 4294967295 << 32 - sigBytes % 4 * 8;
    words.length = Math.ceil(sigBytes / 4);
  }
  /**
   * Creates a copy of this word array.
   *
   * @return {WordArray} The clone.
   *
   * @example
   *
   *     var clone = wordArray.clone();
   */
  clone() {
    const clone = super.clone.call(this);
    clone.words = this.words.slice(0);
    return clone;
  }
}
/**
 * Creates a word array filled with random bytes.
 *
 * @param {number} nBytes The number of random bytes to generate.
 *
 * @return {WordArray} The random word array.
 *
 * @static
 *
 * @example
 *
 *     var wordArray = CryptoJS.lib.WordArray.random(16);
 */
__publicField(WordArray, "random", randomWordArray);
const Hex = {
  /**
   * Converts a word array to a hex string.
   *
   * @param {WordArray} wordArray The word array.
   *
   * @return {string} The hex string.
   *
   * @static
   *
   * @example
   *
   *     var hexString = CryptoJS.enc.Hex.stringify(wordArray);
   */
  stringify(wordArray) {
    const { words, sigBytes } = wordArray;
    const hexChars = [];
    for (let i = 0; i < sigBytes; i += 1) {
      const bite = words[i >>> 2] >>> 24 - i % 4 * 8 & 255;
      hexChars.push((bite >>> 4).toString(16));
      hexChars.push((bite & 15).toString(16));
    }
    return hexChars.join("");
  },
  /**
   * Converts a hex string to a word array.
   *
   * @param {string} hexStr The hex string.
   *
   * @return {WordArray} The word array.
   *
   * @static
   *
   * @example
   *
   *     var wordArray = CryptoJS.enc.Hex.parse(hexString);
   */
  parse(hexStr) {
    const hexStrLength = hexStr.length;
    const words = [];
    for (let i = 0; i < hexStrLength; i += 2) {
      words[i >>> 3] |= parseInt(hexStr.substr(i, 2), 16) << 24 - i % 8 * 4;
    }
    return new WordArray(words, hexStrLength / 2);
  }
};
const Latin1 = {
  /**
   * Converts a word array to a Latin1 string.
   *
   * @param {WordArray} wordArray The word array.
   *
   * @return {string} The Latin1 string.
   *
   * @static
   *
   * @example
   *
   *     var latin1String = CryptoJS.enc.Latin1.stringify(wordArray);
   */
  stringify(wordArray) {
    const { words, sigBytes } = wordArray;
    const latin1Chars = [];
    for (let i = 0; i < sigBytes; i += 1) {
      const bite = words[i >>> 2] >>> 24 - i % 4 * 8 & 255;
      latin1Chars.push(String.fromCharCode(bite));
    }
    return latin1Chars.join("");
  },
  /**
   * Converts a Latin1 string to a word array.
   *
   * @param {string} latin1Str The Latin1 string.
   *
   * @return {WordArray} The word array.
   *
   * @static
   *
   * @example
   *
   *     var wordArray = CryptoJS.enc.Latin1.parse(latin1String);
   */
  parse(latin1Str) {
    const latin1StrLength = latin1Str.length;
    const words = [];
    for (let i = 0; i < latin1StrLength; i += 1) {
      words[i >>> 2] |= (latin1Str.charCodeAt(i) & 255) << 24 - i % 4 * 8;
    }
    return new WordArray(words, latin1StrLength);
  }
};
const Utf8 = {
  /**
   * Converts a word array to a UTF-8 string.
   *
   * @param {WordArray} wordArray The word array.
   *
   * @return {string} The UTF-8 string.
   *
   * @static
   *
   * @example
   *
   *     var utf8String = CryptoJS.enc.Utf8.stringify(wordArray);
   */
  stringify(wordArray) {
    try {
      return decodeURIComponent(escape(Latin1.stringify(wordArray)));
    } catch (e) {
      throw new Error("Malformed UTF-8 data");
    }
  },
  /**
   * Converts a UTF-8 string to a word array.
   *
   * @param {string} utf8Str The UTF-8 string.
   *
   * @return {WordArray} The word array.
   *
   * @static
   *
   * @example
   *
   *     var wordArray = CryptoJS.enc.Utf8.parse(utf8String);
   */
  parse(utf8Str) {
    return Latin1.parse(unescape(encodeURIComponent(utf8Str)));
  }
};
class BufferedBlockAlgorithm extends Base {
  constructor() {
    super();
    this._minBufferSize = 0;
  }
  /**
   * Resets this block algorithm's data buffer to its initial state.
   *
   * @example
   *
   *     bufferedBlockAlgorithm.reset();
   */
  reset() {
    this._data = new WordArray();
    this._nDataBytes = 0;
  }
  /**
   * Adds new data to this block algorithm's buffer.
   *
   * @param {WordArray|string} data
   *
   *     The data to append. Strings are converted to a WordArray using UTF-8.
   *
   * @example
   *
   *     bufferedBlockAlgorithm._append('data');
   *     bufferedBlockAlgorithm._append(wordArray);
   */
  _append(data) {
    let m_data = data;
    if (typeof m_data === "string") {
      m_data = Utf8.parse(m_data);
    }
    this._data.concat(m_data);
    this._nDataBytes += m_data.sigBytes;
  }
  /**
   * Processes available data blocks.
   *
   * This method invokes _doProcessBlock(offset), which must be implemented by a concrete subtype.
   *
   * @param {boolean} doFlush Whether all blocks and partial blocks should be processed.
   *
   * @return {WordArray} The processed data.
   *
   * @example
   *
   *     var processedData = bufferedBlockAlgorithm._process();
   *     var processedData = bufferedBlockAlgorithm._process(!!'flush');
   */
  _process(doFlush) {
    let processedWords;
    const { _data: data, blockSize } = this;
    const dataWords = data.words;
    const dataSigBytes = data.sigBytes;
    const blockSizeBytes = blockSize * 4;
    let nBlocksReady = dataSigBytes / blockSizeBytes;
    if (doFlush) {
      nBlocksReady = Math.ceil(nBlocksReady);
    } else {
      nBlocksReady = Math.max((nBlocksReady | 0) - this._minBufferSize, 0);
    }
    const nWordsReady = nBlocksReady * blockSize;
    const nBytesReady = Math.min(nWordsReady * 4, dataSigBytes);
    if (nWordsReady) {
      for (let offset = 0; offset < nWordsReady; offset += blockSize) {
        this._doProcessBlock(dataWords, offset);
      }
      processedWords = dataWords.splice(0, nWordsReady);
      data.sigBytes -= nBytesReady;
    }
    return new WordArray(processedWords, nBytesReady);
  }
  /**
   * Creates a copy of this object.
   *
   * @return {Object} The clone.
   *
   * @example
   *
   *     var clone = bufferedBlockAlgorithm.clone();
   */
  clone() {
    const clone = super.clone.call(this);
    clone._data = this._data.clone();
    return clone;
  }
}
class Hasher extends BufferedBlockAlgorithm {
  constructor(cfg) {
    super();
    this.blockSize = 512 / 32;
    this.cfg = Object.assign(new Base(), cfg);
    this.reset();
  }
  /**
   * Creates a shortcut function to a hasher's object interface.
   *
   * @param {Hasher} SubHasher The hasher to create a helper for.
   *
   * @return {Function} The shortcut function.
   *
   * @static
   *
   * @example
   *
   *     var SHA256 = CryptoJS.lib.Hasher._createHelper(CryptoJS.algo.SHA256);
   */
  static _createHelper(SubHasher) {
    return (message, cfg) => new SubHasher(cfg).finalize(message);
  }
  /**
   * Creates a shortcut function to the HMAC's object interface.
   *
   * @param {Hasher} SubHasher The hasher to use in this HMAC helper.
   *
   * @return {Function} The shortcut function.
   *
   * @static
   *
   * @example
   *
   *     var HmacSHA256 = CryptoJS.lib.Hasher._createHmacHelper(CryptoJS.algo.SHA256);
   */
  static _createHmacHelper(SubHasher) {
    return (message, key) => new HMAC(SubHasher, key).finalize(message);
  }
  /**
   * Resets this hasher to its initial state.
   *
   * @example
   *
   *     hasher.reset();
   */
  reset() {
    super.reset.call(this);
    this._doReset();
  }
  /**
   * Updates this hasher with a message.
   *
   * @param {WordArray|string} messageUpdate The message to append.
   *
   * @return {Hasher} This hasher.
   *
   * @example
   *
   *     hasher.update('message');
   *     hasher.update(wordArray);
   */
  update(messageUpdate) {
    this._append(messageUpdate);
    this._process();
    return this;
  }
  /**
   * Finalizes the hash computation.
   * Note that the finalize operation is effectively a destructive, read-once operation.
   *
   * @param {WordArray|string} messageUpdate (Optional) A final message update.
   *
   * @return {WordArray} The hash.
   *
   * @example
   *
   *     var hash = hasher.finalize();
   *     var hash = hasher.finalize('message');
   *     var hash = hasher.finalize(wordArray);
   */
  finalize(messageUpdate) {
    if (messageUpdate) {
      this._append(messageUpdate);
    }
    const hash = this._doFinalize();
    return hash;
  }
}
class HMAC extends Base {
  /**
   * Initializes a newly created HMAC.
   *
   * @param {Hasher} SubHasher The hash algorithm to use.
   * @param {WordArray|string} key The secret key.
   *
   * @example
   *
   *     var hmacHasher = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, key);
   */
  constructor(SubHasher, key) {
    super();
    const hasher = new SubHasher();
    this._hasher = hasher;
    let _key = key;
    if (typeof _key === "string") {
      _key = Utf8.parse(_key);
    }
    const hasherBlockSize = hasher.blockSize;
    const hasherBlockSizeBytes = hasherBlockSize * 4;
    if (_key.sigBytes > hasherBlockSizeBytes) {
      _key = hasher.finalize(key);
    }
    _key.clamp();
    const oKey = _key.clone();
    this._oKey = oKey;
    const iKey = _key.clone();
    this._iKey = iKey;
    const oKeyWords = oKey.words;
    const iKeyWords = iKey.words;
    for (let i = 0; i < hasherBlockSize; i += 1) {
      oKeyWords[i] ^= 1549556828;
      iKeyWords[i] ^= 909522486;
    }
    oKey.sigBytes = hasherBlockSizeBytes;
    iKey.sigBytes = hasherBlockSizeBytes;
    this.reset();
  }
  /**
   * Resets this HMAC to its initial state.
   *
   * @example
   *
   *     hmacHasher.reset();
   */
  reset() {
    const hasher = this._hasher;
    hasher.reset();
    hasher.update(this._iKey);
  }
  /**
   * Updates this HMAC with a message.
   *
   * @param {WordArray|string} messageUpdate The message to append.
   *
   * @return {HMAC} This HMAC instance.
   *
   * @example
   *
   *     hmacHasher.update('message');
   *     hmacHasher.update(wordArray);
   */
  update(messageUpdate) {
    this._hasher.update(messageUpdate);
    return this;
  }
  /**
   * Finalizes the HMAC computation.
   * Note that the finalize operation is effectively a destructive, read-once operation.
   *
   * @param {WordArray|string} messageUpdate (Optional) A final message update.
   *
   * @return {WordArray} The HMAC.
   *
   * @example
   *
   *     var hmac = hmacHasher.finalize();
   *     var hmac = hmacHasher.finalize('message');
   *     var hmac = hmacHasher.finalize(wordArray);
   */
  finalize(messageUpdate) {
    const hasher = this._hasher;
    const innerHash = hasher.finalize(messageUpdate);
    hasher.reset();
    const hmac = hasher.finalize(this._oKey.clone().concat(innerHash));
    return hmac;
  }
}
const parseLoop = (base64Str, base64StrLength, reverseMap) => {
  const words = [];
  let nBytes = 0;
  for (let i = 0; i < base64StrLength; i += 1) {
    if (i % 4) {
      const bits1 = reverseMap[base64Str.charCodeAt(i - 1)] << i % 4 * 2;
      const bits2 = reverseMap[base64Str.charCodeAt(i)] >>> 6 - i % 4 * 2;
      const bitsCombined = bits1 | bits2;
      words[nBytes >>> 2] |= bitsCombined << 24 - nBytes % 4 * 8;
      nBytes += 1;
    }
  }
  return WordArray.create(words, nBytes);
};
const Base64 = {
  /**
   * Converts a word array to a Base64 string.
   *
   * @param {WordArray} wordArray The word array.
   *
   * @return {string} The Base64 string.
   *
   * @static
   *
   * @example
   *
   *     const base64String = CryptoJS.enc.Base64.stringify(wordArray);
   */
  stringify(wordArray) {
    const { words, sigBytes } = wordArray;
    const map = this._map;
    wordArray.clamp();
    const base64Chars = [];
    for (let i = 0; i < sigBytes; i += 3) {
      const byte1 = words[i >>> 2] >>> 24 - i % 4 * 8 & 255;
      const byte2 = words[i + 1 >>> 2] >>> 24 - (i + 1) % 4 * 8 & 255;
      const byte3 = words[i + 2 >>> 2] >>> 24 - (i + 2) % 4 * 8 & 255;
      const triplet = byte1 << 16 | byte2 << 8 | byte3;
      for (let j = 0; j < 4 && i + j * 0.75 < sigBytes; j += 1) {
        base64Chars.push(map.charAt(triplet >>> 6 * (3 - j) & 63));
      }
    }
    const paddingChar = map.charAt(64);
    if (paddingChar) {
      while (base64Chars.length % 4) {
        base64Chars.push(paddingChar);
      }
    }
    return base64Chars.join("");
  },
  /**
   * Converts a Base64 string to a word array.
   *
   * @param {string} base64Str The Base64 string.
   *
   * @return {WordArray} The word array.
   *
   * @static
   *
   * @example
   *
   *     const wordArray = CryptoJS.enc.Base64.parse(base64String);
   */
  parse(base64Str) {
    let base64StrLength = base64Str.length;
    const map = this._map;
    let reverseMap = this._reverseMap;
    if (!reverseMap) {
      this._reverseMap = [];
      reverseMap = this._reverseMap;
      for (let j = 0; j < map.length; j += 1) {
        reverseMap[map.charCodeAt(j)] = j;
      }
    }
    const paddingChar = map.charAt(64);
    if (paddingChar) {
      const paddingIndex = base64Str.indexOf(paddingChar);
      if (paddingIndex !== -1) {
        base64StrLength = paddingIndex;
      }
    }
    return parseLoop(base64Str, base64StrLength, reverseMap);
  },
  _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
};
const H = [];
const K = [];
const isPrime = (n2) => {
  const sqrtN = Math.sqrt(n2);
  for (let factor = 2; factor <= sqrtN; factor += 1) {
    if (!(n2 % factor)) {
      return false;
    }
  }
  return true;
};
const getFractionalBits = (n2) => (n2 - (n2 | 0)) * 4294967296 | 0;
let n = 2;
let nPrime = 0;
while (nPrime < 64) {
  if (isPrime(n)) {
    if (nPrime < 8) {
      H[nPrime] = getFractionalBits(n ** (1 / 2));
    }
    K[nPrime] = getFractionalBits(n ** (1 / 3));
    nPrime += 1;
  }
  n += 1;
}
const W = [];
class SHA256Algo extends Hasher {
  _doReset() {
    this._hash = new WordArray(H.slice(0));
  }
  _doProcessBlock(M, offset) {
    const _H = this._hash.words;
    let a = _H[0];
    let b = _H[1];
    let c = _H[2];
    let d = _H[3];
    let e = _H[4];
    let f = _H[5];
    let g = _H[6];
    let h = _H[7];
    for (let i = 0; i < 64; i += 1) {
      if (i < 16) {
        W[i] = M[offset + i] | 0;
      } else {
        const gamma0x = W[i - 15];
        const gamma0 = (gamma0x << 25 | gamma0x >>> 7) ^ (gamma0x << 14 | gamma0x >>> 18) ^ gamma0x >>> 3;
        const gamma1x = W[i - 2];
        const gamma1 = (gamma1x << 15 | gamma1x >>> 17) ^ (gamma1x << 13 | gamma1x >>> 19) ^ gamma1x >>> 10;
        W[i] = gamma0 + W[i - 7] + gamma1 + W[i - 16];
      }
      const ch = e & f ^ ~e & g;
      const maj = a & b ^ a & c ^ b & c;
      const sigma0 = (a << 30 | a >>> 2) ^ (a << 19 | a >>> 13) ^ (a << 10 | a >>> 22);
      const sigma1 = (e << 26 | e >>> 6) ^ (e << 21 | e >>> 11) ^ (e << 7 | e >>> 25);
      const t1 = h + sigma1 + ch + K[i] + W[i];
      const t2 = sigma0 + maj;
      h = g;
      g = f;
      f = e;
      e = d + t1 | 0;
      d = c;
      c = b;
      b = a;
      a = t1 + t2 | 0;
    }
    _H[0] = _H[0] + a | 0;
    _H[1] = _H[1] + b | 0;
    _H[2] = _H[2] + c | 0;
    _H[3] = _H[3] + d | 0;
    _H[4] = _H[4] + e | 0;
    _H[5] = _H[5] + f | 0;
    _H[6] = _H[6] + g | 0;
    _H[7] = _H[7] + h | 0;
  }
  _doFinalize() {
    const data = this._data;
    const dataWords = data.words;
    const nBitsTotal = this._nDataBytes * 8;
    const nBitsLeft = data.sigBytes * 8;
    dataWords[nBitsLeft >>> 5] |= 128 << 24 - nBitsLeft % 32;
    dataWords[(nBitsLeft + 64 >>> 9 << 4) + 14] = Math.floor(nBitsTotal / 4294967296);
    dataWords[(nBitsLeft + 64 >>> 9 << 4) + 15] = nBitsTotal;
    data.sigBytes = dataWords.length * 4;
    this._process();
    return this._hash;
  }
  clone() {
    const clone = super.clone.call(this);
    clone._hash = this._hash.clone();
    return clone;
  }
}
const SHA256 = Hasher._createHelper(SHA256Algo);
function usePkce() {
  const { config } = useConfigStore();
  function generateCodeVerifier() {
    const length = Math.floor(Math.random() * (128 - 43 + 1) + 43);
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    let codeVerifier = "";
    for (let i = 0; i < length; i++) {
      codeVerifier += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return codeVerifier;
  }
  function generateCodeChallenge(codeVerifier) {
    const hashed = SHA256(codeVerifier);
    return Base64.stringify(hashed).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
  }
  function generateRandomState() {
    return Math.random().toString(36).substring(7);
  }
  function generateAuthorizationUrl() {
    var _a2;
    if (!config.value) {
      console.error("config not set.", config.value);
      throw new Error("Config not set");
    }
    console.log(config.value);
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = generateCodeChallenge(codeVerifier);
    const state = generateRandomState();
    sessionStorage.setItem("codeVerifier", codeVerifier);
    sessionStorage.setItem("state", state);
    const searchParams = new URLSearchParams();
    searchParams.append("client_id", `${config.value.loginClientId}`);
    searchParams.append("redirect_uri", `${config.value.loginRedirect}/oauth/v2/callback`);
    searchParams.append("response_type", "code");
    searchParams.append("state", state);
    searchParams.append("code_challenge_method", "S256");
    searchParams.append("code_challenge", codeChallenge);
    const loginUrl = (_a2 = config.value) == null ? void 0 : _a2.loginUrl;
    if (!loginUrl) {
      throw new Error("Login URL not set");
    }
    return `${loginUrl.toString()}/oauth/v2/authorize?${searchParams.toString()}`;
  }
  async function exchangeAuthorizationCode(authorizationCode, state) {
    if (!config.value) {
      throw new Error("Config not set");
    }
    const codeVerifier = sessionStorage.getItem("codeVerifier");
    const sessionState = sessionStorage.getItem("state");
    if (state !== sessionState) {
      throw new Error("Invalid state parameter");
    }
    const res = await api.post("/oauth/v2/token", {
      grant_type: "authorization_code",
      code: authorizationCode,
      code_verifier: codeVerifier,
      client_id: config.value.loginClientId,
      // Replace with your client ID.
      redirect_uri: `${config.value.loginRedirect}/oauth/v2/callback`
      // Replace with your callback URL.
    }, { baseURL: `${config.value.loginUrl}` });
    if (res.status !== 200) {
      console.error(res.status, res.data);
      throw new Error("Failed to exchange authorization code with access token.");
    }
    return res.data;
  }
  return {
    exchangeAuthorizationCode,
    generateAuthorizationUrl
  };
}
var LoginTypeEnum = /* @__PURE__ */ ((LoginTypeEnum2) => {
  LoginTypeEnum2["CMS"] = "verbleif_cms";
  LoginTypeEnum2["WEB"] = "verbleif_web";
  return LoginTypeEnum2;
})(LoginTypeEnum || {});
const LogLevels = {
  silent: Number.NEGATIVE_INFINITY,
  fatal: 0,
  error: 0,
  warn: 1,
  log: 2,
  info: 3,
  success: 3,
  fail: 3,
  ready: 3,
  start: 3,
  box: 3,
  debug: 4,
  trace: 5,
  verbose: Number.POSITIVE_INFINITY
};
const LogTypes = {
  // Silent
  silent: {
    level: -1
  },
  // Level 0
  fatal: {
    level: LogLevels.fatal
  },
  error: {
    level: LogLevels.error
  },
  // Level 1
  warn: {
    level: LogLevels.warn
  },
  // Level 2
  log: {
    level: LogLevels.log
  },
  // Level 3
  info: {
    level: LogLevels.info
  },
  success: {
    level: LogLevels.success
  },
  fail: {
    level: LogLevels.fail
  },
  ready: {
    level: LogLevels.info
  },
  start: {
    level: LogLevels.info
  },
  box: {
    level: LogLevels.info
  },
  // Level 4
  debug: {
    level: LogLevels.debug
  },
  // Level 5
  trace: {
    level: LogLevels.trace
  },
  // Verbose
  verbose: {
    level: LogLevels.verbose
  }
};
function isObject(value) {
  return value !== null && typeof value === "object";
}
function _defu(baseObject, defaults, namespace = ".", merger) {
  if (!isObject(defaults)) {
    return _defu(baseObject, {}, namespace);
  }
  const object = Object.assign({}, defaults);
  for (const key in baseObject) {
    if (key === "__proto__" || key === "constructor") {
      continue;
    }
    const value = baseObject[key];
    if (value === null || value === void 0) {
      continue;
    }
    if (Array.isArray(value) && Array.isArray(object[key])) {
      object[key] = [...value, ...object[key]];
    } else if (isObject(value) && isObject(object[key])) {
      object[key] = _defu(
        value,
        object[key],
        (namespace ? `${namespace}.` : "") + key.toString()
      );
    } else {
      object[key] = value;
    }
  }
  return object;
}
function createDefu(merger) {
  return (...arguments_) => (
    // eslint-disable-next-line unicorn/no-array-reduce
    arguments_.reduce((p, c) => _defu(p, c, ""), {})
  );
}
const defu = createDefu();
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
function isLogObj(arg) {
  if (!isPlainObject(arg)) {
    return false;
  }
  if (!arg.message && !arg.args) {
    return false;
  }
  if (arg.stack) {
    return false;
  }
  return true;
}
let paused = false;
const queue = [];
class Consola {
  constructor(options = {}) {
    const types = options.types || LogTypes;
    this.options = defu(
      {
        ...options,
        defaults: { ...options.defaults },
        level: _normalizeLogLevel(options.level, types),
        reporters: [...options.reporters || []]
      },
      {
        types: LogTypes,
        throttle: 1e3,
        throttleMin: 5,
        formatOptions: {
          date: true,
          colors: false,
          compact: true
        }
      }
    );
    for (const type in types) {
      const defaults = {
        type,
        ...this.options.defaults,
        ...types[type]
      };
      this[type] = this._wrapLogFn(defaults);
      this[type].raw = this._wrapLogFn(
        defaults,
        true
      );
    }
    if (this.options.mockFn) {
      this.mockTypes();
    }
    this._lastLog = {};
  }
  get level() {
    return this.options.level;
  }
  set level(level2) {
    this.options.level = _normalizeLogLevel(
      level2,
      this.options.types,
      this.options.level
    );
  }
  prompt(message, opts) {
    if (!this.options.prompt) {
      throw new Error("prompt is not supported!");
    }
    return this.options.prompt(message, opts);
  }
  create(options) {
    const instance = new Consola({
      ...this.options,
      ...options
    });
    if (this._mockFn) {
      instance.mockTypes(this._mockFn);
    }
    return instance;
  }
  withDefaults(defaults) {
    return this.create({
      ...this.options,
      defaults: {
        ...this.options.defaults,
        ...defaults
      }
    });
  }
  withTag(tag) {
    return this.withDefaults({
      tag: this.options.defaults.tag ? this.options.defaults.tag + ":" + tag : tag
    });
  }
  addReporter(reporter) {
    this.options.reporters.push(reporter);
    return this;
  }
  removeReporter(reporter) {
    if (reporter) {
      const i = this.options.reporters.indexOf(reporter);
      if (i >= 0) {
        return this.options.reporters.splice(i, 1);
      }
    } else {
      this.options.reporters.splice(0);
    }
    return this;
  }
  setReporters(reporters) {
    this.options.reporters = Array.isArray(reporters) ? reporters : [reporters];
    return this;
  }
  wrapAll() {
    this.wrapConsole();
    this.wrapStd();
  }
  restoreAll() {
    this.restoreConsole();
    this.restoreStd();
  }
  wrapConsole() {
    for (const type in this.options.types) {
      if (!console["__" + type]) {
        console["__" + type] = console[type];
      }
      console[type] = this[type].raw;
    }
  }
  restoreConsole() {
    for (const type in this.options.types) {
      if (console["__" + type]) {
        console[type] = console["__" + type];
        delete console["__" + type];
      }
    }
  }
  wrapStd() {
    this._wrapStream(this.options.stdout, "log");
    this._wrapStream(this.options.stderr, "log");
  }
  _wrapStream(stream, type) {
    if (!stream) {
      return;
    }
    if (!stream.__write) {
      stream.__write = stream.write;
    }
    stream.write = (data) => {
      this[type].raw(String(data).trim());
    };
  }
  restoreStd() {
    this._restoreStream(this.options.stdout);
    this._restoreStream(this.options.stderr);
  }
  _restoreStream(stream) {
    if (!stream) {
      return;
    }
    if (stream.__write) {
      stream.write = stream.__write;
      delete stream.__write;
    }
  }
  pauseLogs() {
    paused = true;
  }
  resumeLogs() {
    paused = false;
    const _queue = queue.splice(0);
    for (const item of _queue) {
      item[0]._logFn(item[1], item[2]);
    }
  }
  mockTypes(mockFn) {
    const _mockFn = mockFn || this.options.mockFn;
    this._mockFn = _mockFn;
    if (typeof _mockFn !== "function") {
      return;
    }
    for (const type in this.options.types) {
      this[type] = _mockFn(type, this.options.types[type]) || this[type];
      this[type].raw = this[type];
    }
  }
  _wrapLogFn(defaults, isRaw) {
    return (...args) => {
      if (paused) {
        queue.push([this, defaults, args, isRaw]);
        return;
      }
      return this._logFn(defaults, args, isRaw);
    };
  }
  _logFn(defaults, args, isRaw) {
    if ((defaults.level || 0) > this.level) {
      return false;
    }
    const logObj = {
      date: /* @__PURE__ */ new Date(),
      args: [],
      ...defaults,
      level: _normalizeLogLevel(defaults.level, this.options.types)
    };
    if (!isRaw && args.length === 1 && isLogObj(args[0])) {
      Object.assign(logObj, args[0]);
    } else {
      logObj.args = [...args];
    }
    if (logObj.message) {
      logObj.args.unshift(logObj.message);
      delete logObj.message;
    }
    if (logObj.additional) {
      if (!Array.isArray(logObj.additional)) {
        logObj.additional = logObj.additional.split("\n");
      }
      logObj.args.push("\n" + logObj.additional.join("\n"));
      delete logObj.additional;
    }
    logObj.type = typeof logObj.type === "string" ? logObj.type.toLowerCase() : "log";
    logObj.tag = typeof logObj.tag === "string" ? logObj.tag : "";
    const resolveLog = (newLog = false) => {
      const repeated = (this._lastLog.count || 0) - this.options.throttleMin;
      if (this._lastLog.object && repeated > 0) {
        const args2 = [...this._lastLog.object.args];
        if (repeated > 1) {
          args2.push(`(repeated ${repeated} times)`);
        }
        this._log({ ...this._lastLog.object, args: args2 });
        this._lastLog.count = 1;
      }
      if (newLog) {
        this._lastLog.object = logObj;
        this._log(logObj);
      }
    };
    clearTimeout(this._lastLog.timeout);
    const diffTime = this._lastLog.time && logObj.date ? logObj.date.getTime() - this._lastLog.time.getTime() : 0;
    this._lastLog.time = logObj.date;
    if (diffTime < this.options.throttle) {
      try {
        const serializedLog = JSON.stringify([
          logObj.type,
          logObj.tag,
          logObj.args
        ]);
        const isSameLog = this._lastLog.serialized === serializedLog;
        this._lastLog.serialized = serializedLog;
        if (isSameLog) {
          this._lastLog.count = (this._lastLog.count || 0) + 1;
          if (this._lastLog.count > this.options.throttleMin) {
            this._lastLog.timeout = setTimeout(
              resolveLog,
              this.options.throttle
            );
            return;
          }
        }
      } catch {
      }
    }
    resolveLog(true);
  }
  _log(logObj) {
    for (const reporter of this.options.reporters) {
      reporter.log(logObj, {
        options: this.options
      });
    }
  }
}
function _normalizeLogLevel(input, types = {}, defaultLevel = 3) {
  if (input === void 0) {
    return defaultLevel;
  }
  if (typeof input === "number") {
    return input;
  }
  if (types[input] && types[input].level !== void 0) {
    return types[input].level;
  }
  return defaultLevel;
}
Consola.prototype.add = Consola.prototype.addReporter;
Consola.prototype.remove = Consola.prototype.removeReporter;
Consola.prototype.clear = Consola.prototype.removeReporter;
Consola.prototype.withScope = Consola.prototype.withTag;
Consola.prototype.mock = Consola.prototype.mockTypes;
Consola.prototype.pause = Consola.prototype.pauseLogs;
Consola.prototype.resume = Consola.prototype.resumeLogs;
function createConsola$1(options = {}) {
  return new Consola(options);
}
class BrowserReporter {
  constructor(options) {
    this.options = { ...options };
    this.defaultColor = "#7f8c8d";
    this.levelColorMap = {
      0: "#c0392b",
      // Red
      1: "#f39c12",
      // Yellow
      3: "#00BCD4"
      // Cyan
    };
    this.typeColorMap = {
      success: "#2ecc71"
      // Green
    };
  }
  _getLogFn(level2) {
    if (level2 < 1) {
      return console.__error || console.error;
    }
    if (level2 === 1) {
      return console.__warn || console.warn;
    }
    return console.__log || console.log;
  }
  log(logObj) {
    const consoleLogFn = this._getLogFn(logObj.level);
    const type = logObj.type === "log" ? "" : logObj.type;
    const tag = logObj.tag || "";
    const color = this.typeColorMap[logObj.type] || this.levelColorMap[logObj.level] || this.defaultColor;
    const style = `
      background: ${color};
      border-radius: 0.5em;
      color: white;
      font-weight: bold;
      padding: 2px 0.5em;
    `;
    const badge = `%c${[tag, type].filter(Boolean).join(":")}`;
    if (typeof logObj.args[0] === "string") {
      consoleLogFn(
        `${badge}%c ${logObj.args[0]}`,
        style,
        // Empty string as style resets to default console style
        "",
        ...logObj.args.slice(1)
      );
    } else {
      consoleLogFn(badge, style, ...logObj.args);
    }
  }
}
function createConsola(options = {}) {
  const consola2 = createConsola$1({
    reporters: options.reporters || [new BrowserReporter({})],
    prompt(message, options2 = {}) {
      if (options2.type === "confirm") {
        return Promise.resolve(confirm(message));
      }
      return Promise.resolve(prompt(message));
    },
    ...options
  });
  return consola2;
}
createConsola();
const level = LogLevels.info;
const consola = createConsola({
  level: Number.parseInt(level)
});
function createImpersonationStore() {
  const impersonationUserIdentifier = useSessionStorage("impersonationUserIdentifier", null);
  const impersonationClientIdentifier = useSessionStorage("impersonationClientIdentifier", null);
  const isImpersonating = computed(() => {
    return impersonationUserIdentifier.value !== null && impersonationClientIdentifier.value !== null;
  });
  const getRequestHeaders = computed(() => {
    const headers = {};
    if (impersonationUserIdentifier.value !== null && impersonationClientIdentifier.value !== null) {
      headers["X-Switch-Type"] = "verbleif_web";
      headers["X-Switch-Username"] = impersonationUserIdentifier.value;
      headers["X-Switch-Client-Id"] = impersonationClientIdentifier.value;
    }
    return headers;
  });
  function setImpersonationUserIdentifier(id) {
    impersonationUserIdentifier.value = id;
  }
  function setImpersonationClientIdentifier(id) {
    impersonationClientIdentifier.value = id;
  }
  function reset() {
    impersonationUserIdentifier.value = null;
    impersonationClientIdentifier.value = null;
  }
  return {
    isImpersonating,
    impersonationUserIdentifier,
    impersonationClientIdentifier,
    getRequestHeaders,
    setImpersonationUserIdentifier,
    setImpersonationClientIdentifier,
    reset
  };
}
let backwardsCompatibleInstance = null;
function useImpersonationStore() {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createImpersonationStore();
  }
  return backwardsCompatibleInstance;
}
const useLocationStore = createGlobalState(
  () => {
    const locations = ref({});
    const defaultLocation = useLocalStorage("defaultLocation", null);
    const selectedLocation = useSessionStorage("selectedLocation", null);
    if (defaultLocation.value && !selectedLocation.value) {
      selectedLocation.value = defaultLocation.value;
    }
    const locationsList = computed(() => {
      if (!locations.value) {
        return [];
      }
      return Object.values(locations.value);
    });
    const selectedLocationId = computed(() => {
      return selectedLocation.value ? iriToId(selectedLocation.value) : null;
    });
    const isAnyImportRunning = computed(() => {
      if (!locationsList.value) {
        return false;
      }
      return locationsList.value.filter((l) => l.importRunning).length > 0;
    });
    const hasMultipleLocations = computed(() => {
      if (!locations.value) {
        return false;
      }
      return locationsList.value.length > 1;
    });
    const selectedLocationObject = computed(() => {
      var _a2;
      if (!selectedLocation.value) {
        return null;
      }
      return ((_a2 = locations.value) == null ? void 0 : _a2[selectedLocation.value]) || null;
    });
    const getSelectedLocationId = computed(() => {
      var _a2;
      return ((_a2 = selectedLocationObject.value) == null ? void 0 : _a2.id) || null;
    });
    function setLocations(newLocations) {
      var _a2, _b2;
      locations.value = newLocations.reduce((a, v) => {
        return { ...a, [v["@id"]]: v };
      }, {});
      const newLocation = newLocations == null ? void 0 : newLocations[0];
      if (selectedLocation.value && !((_a2 = locations.value) == null ? void 0 : _a2[selectedLocation.value])) {
        setSelectedLocation(newLocation["@id"]);
      }
      if (defaultLocation.value && !((_b2 = locations.value) == null ? void 0 : _b2[defaultLocation.value])) {
        setDefaultLocation(newLocation["@id"]);
      }
      if (newLocations.length === 1 && selectedLocation.value !== newLocation["@id"]) {
        setSelectedLocation(newLocation["@id"]);
      } else if (defaultLocation.value && !selectedLocation.value) {
        setSelectedLocation(defaultLocation.value);
      }
    }
    function setImportRunningForLocationIri(iri, isRunning) {
      if (!locations.value) {
        console.error("Cant set setImportRunningForLocationId since there are no locations");
        return;
      }
      const index = locations.value[iri];
      if (!index) {
        return;
      }
      locations.value[iri].importRunning = isRunning;
    }
    function setDefaultLocation(val) {
      defaultLocation.value = val;
    }
    function setSelectedLocation(val) {
      selectedLocation.value = val;
    }
    function reset() {
      setSelectedLocation(null);
      locations.value = {};
      defaultLocation.value = null;
    }
    const hasMoreThanOneLocation = computed(() => {
      return locationsList.value.length > 1;
    });
    return {
      locationsList,
      locations,
      selectedLocation,
      selectedLocationId,
      hasMoreThanOneLocation,
      selectedLocationObject,
      hasMultipleLocations,
      isAnyImportRunning,
      getSelectedLocationId,
      reset,
      defaultLocation,
      setSelectedLocation,
      setDefaultLocation,
      setImportRunningForLocationIri,
      setLocations
    };
  }
);
var Feature = /* @__PURE__ */ ((Feature2) => {
  Feature2["BOOKING_EXPERTS"] = "booking-experts";
  Feature2["APP"] = "app";
  Feature2["WEB_FIREBASE_NOTIFICATIONS"] = "firebase";
  Feature2["CUSTOM_REPORT_LISTS"] = "custom-report-lists";
  Feature2["CSV_IMPORT"] = "csv";
  Feature2["CSV_IMPORT_ADVANCED"] = "csv-import-advanced";
  Feature2["SUBSCRIPTION_MODULE"] = "subscription";
  Feature2["LOCATION_MODULE"] = "locations";
  Feature2["ROLES_MODULE"] = "roles";
  Feature2["SORT_TYPES"] = "sort-types";
  Feature2["TASKS_DONE_REPORTING"] = "task-status-done";
  Feature2["CHANGE_OVER_DAY_MODULE"] = "change-over-day";
  Feature2["TASK_LISTS"] = "task-lists";
  Feature2["TASK_CREATE_BLOCKING_DEFAULT_TRUE"] = "task-create-blocking-default-true";
  Feature2["TASKS_DATE_FILTER"] = "tasks-date-filter";
  Feature2["MANUAL_THIRD_PARTY_FIELDS"] = "manual-third-party-fields";
  Feature2["HIDE_REPORTS_DEPARTMENTS"] = "hide-reports-departments";
  Feature2["NOTIFY_DP_MANAGERS"] = "notifications-to-department-managers";
  Feature2["PUBLIC_REPORTS"] = "public-reports";
  Feature2["TASK_STATUS_CHANGE_NOTIFICATION"] = "task-status-change-notification";
  Feature2["TASK_CREATED_NOTIFICATION_DP_MANAGERS"] = "task-created-notifications-to-department-managers";
  Feature2["TASK_COMMENT_NOTIFICATIONS"] = "task-comments-notification";
  Feature2["APP_FOCUSED_ON_LOOP_ROUTE"] = "focused_tasks_route";
  Feature2["STRATECH_CONNECTION"] = "stratech";
  Feature2["HIDE_CREATE_TASK"] = "hide-task-create";
  Feature2["DISABLE_REPORT_NOTIFICATIONS"] = "disable-report-notifications";
  Feature2["APP_FOCUSED_ON_MY_TASKS_TILL_TODAY"] = "focused_tasks_until_today";
  Feature2["ASYNC_BEX_AUTOMATIONS"] = "use-bex-automations";
  Feature2["PUBLIC_NOTIFICATIONS_GREAT_STAY_APP"] = "great-stay-app";
  Feature2["PUBLIC_NOTIFICATIONS_WEMA"] = "wema-mobile";
  Feature2["HIDE_REPORTS"] = "hide-reports";
  Feature2["HAS_ADVANCED_TASKS_EXPORT"] = "has-advanced-tasks-export";
  Feature2["ANY_CAN_ASSIGN_TO_FRONTDESK"] = "any-can-assign-to-frontdesk";
  Feature2["ONLY_DEPARTMENT_MANAGER_CAN_ASSIGN_TO_FRONTDESK"] = "only-department-manager-can-assign-to-frontdesk";
  Feature2["TAG_USERS"] = "tag-users";
  Feature2["TWO_FACTOR_AUTHENTICATION"] = "two-factor-authentication-on-account";
  Feature2["ACTIVE_SESSIONS"] = "active-sessions";
  Feature2["HIDE_TIMEZONES"] = "hide-timezones";
  Feature2["ALLOW_ANY_TASK_STATUS_COMPLETED"] = "allow-any-task-status-completed";
  return Feature2;
})(Feature || {});
var UserRole = /* @__PURE__ */ ((UserRole2) => {
  UserRole2[UserRole2["ROLE_ADMIN"] = 0] = "ROLE_ADMIN";
  UserRole2[UserRole2["ROLE_MANAGER"] = 1] = "ROLE_MANAGER";
  UserRole2[UserRole2["ROLE_PARK_MANAGER"] = 2] = "ROLE_PARK_MANAGER";
  UserRole2[UserRole2["ROLE_FRONT_DESK"] = 3] = "ROLE_FRONT_DESK";
  UserRole2[UserRole2["ROLE_DEPARTMENT_MANAGER"] = 4] = "ROLE_DEPARTMENT_MANAGER";
  UserRole2[UserRole2["ROLE_USER"] = 5] = "ROLE_USER";
  return UserRole2;
})(UserRole || {});
function createRightsStore(authStore) {
  const effectiveFeatures = ref([]);
  const roles = [
    0,
    1,
    3,
    4,
    5
    /* ROLE_USER */
  ];
  watch(
    authStore.user,
    () => {
      if (hasFeature(
        "locations"
        /* LOCATION_MODULE */
      ) && !roles.includes(
        2
        /* ROLE_PARK_MANAGER */
      )) {
        roles.push(
          2
          /* ROLE_PARK_MANAGER */
        );
      }
      roles.sort();
    },
    { immediate: true }
  );
  function hasRole(_roleName, _subject = authStore.user.value) {
    return true;
  }
  function hasRoleStrict(roleName, subject = authStore.user.value) {
    if (!subject) {
      return false;
    }
    return subject.roles.some((role) => role.roleName === UserRole[roleName]);
  }
  function getRoles(excludeOwnRole = true, subject = authStore.user.value) {
    var _a2;
    if (!subject) {
      return [];
    }
    const highestRoleString = (_a2 = subject.roles) == null ? void 0 : _a2[0];
    if (typeof highestRoleString !== "string") {
      throw new TypeError(`User with id ${subject.id} has no roles.`);
    }
    if (!(highestRoleString in UserRole)) {
      throw new Error(`Role ${highestRoleString} does not exist inside enum.`);
    }
    const highestUserRole = UserRole[highestRoleString];
    if (excludeOwnRole) {
      return roles.filter((i) => i !== highestUserRole);
    }
    return roles;
  }
  function reset() {
    effectiveFeatures.value = [];
  }
  function hasFeature(inputFeature, inputEffectiveFeatures = null) {
    let didMatch = false;
    if (!inputEffectiveFeatures) {
      inputEffectiveFeatures = effectiveFeatures.value;
    }
    for (const feature of inputEffectiveFeatures) {
      if (typeof feature === "string") {
        if (feature === inputFeature) {
          didMatch = true;
          break;
        }
        continue;
      }
      if (feature.alias === inputFeature.toString()) {
        didMatch = true;
        break;
      }
    }
    return didMatch;
  }
  function setEffectiveFeatures(features) {
    effectiveFeatures.value = features;
  }
  return {
    hasRoleStrict,
    getRoles,
    hasRole,
    hasFeature,
    setEffectiveFeatures,
    reset
  };
}
var ThemeSetting = /* @__PURE__ */ ((ThemeSetting2) => {
  ThemeSetting2["DARK"] = "dark";
  ThemeSetting2["LIGHT"] = "light";
  ThemeSetting2["AUTO"] = "auto";
  return ThemeSetting2;
})(ThemeSetting || {});
var Theme = /* @__PURE__ */ ((Theme2) => {
  Theme2["DARK"] = "dark";
  Theme2["LIGHT"] = "light";
  return Theme2;
})(Theme || {});
const LOCAL_STORAGE_KEY = "themeSetting";
const DEFAULT_THEME = "auto";
function createTheme() {
  const themeSetting = ref(loadTheme());
  const systemPreferredTheme = ref(getSystemPreferredTheme());
  const theme2 = computed(() => {
    if (themeSetting.value === "auto") {
      return systemPreferredTheme.value;
    }
    return themeSetting.value;
  });
  watchEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, themeSetting.value);
    document.body.setAttribute("data-theme", theme2.value);
    if (theme2.value === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    if (themeSetting.value === "auto") {
      enableWatchSystemTheme();
      return;
    }
    disableWatchSystemTheme();
  });
  function loadTheme() {
    let storedTheme = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!storedTheme || storedTheme && !["auto", "dark", "light"].includes(storedTheme)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, DEFAULT_THEME);
      storedTheme = DEFAULT_THEME;
    }
    return storedTheme;
  }
  const isAuto = computed(() => {
    return themeSetting.value === "auto";
  });
  function getSystemPreferredTheme() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
  function handleMatchMediaChange(e) {
    systemPreferredTheme.value = e.matches ? "dark" : "light";
  }
  function enableWatchSystemTheme() {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleMatchMediaChange);
  }
  function disableWatchSystemTheme() {
    window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleMatchMediaChange);
  }
  return {
    theme: theme2,
    isAuto,
    themeSetting
  };
}
const theme = createTheme();
function useTheme() {
  return theme;
}
export {
  AcceptLanguageRequestInterceptor,
  AuthPath,
  BaseUrl,
  BaseUrlRequestInterceptor,
  ClientError,
  ErrorHandlerResponseInterceptor,
  Feature,
  HeaderRequestInterceptor,
  JwtBearerTokenRequestInterceptor,
  LegacyUrlPrefix,
  LoginTypeEnum,
  NetworkError,
  RefreshTokenResponseInterceptor,
  ServerError,
  Theme,
  ThemeSetting,
  UserRole,
  api,
  consola,
  contentTypeInterceptor,
  createAuthenticatePlugin,
  createRightsStore,
  getAll,
  installInterceptor,
  theme,
  useAuthStore,
  useConfigStore,
  useImpersonationStore,
  useLocationStore,
  usePkce,
  useTheme
};
