import actions from "./actions.json";
import attachments from "./attachments.json";
import history from "./history.json";
import information from "./information.json";
import reports from "./reports.json";
import sidebar from "./sidebar.json";
import transferToTask from "./transferToTask.json";
import type from "./type.json";

export default {
  ...reports,
  sidebar,
  attachments,
  type,
  information,
  history,
  actions,
  transferToTask,
};
