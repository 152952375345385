import { computed, inject, ref } from "vue";

const UpdaterSymbol = Symbol("Updater");

export const updater = createUpdater();

export function createUpdater() {
  const update = ref(false);
  const worker = ref(null);

  const hasUpdate = computed(() => {
    return update.value;
  });

  function pushUpdate(workerInstance) {
    update.value = true;
    worker.value = workerInstance;
  }

  function installUpdate() {
    if (!worker.value) {
      console.warn("No worker found.");
      return;
    }
    worker.value.postMessage({ type: "SKIP_WAITING" });
  }

  return {
    hasUpdate,
    pushUpdate,
    installUpdate,
  };
}

export function provideUpdater() {
  return {
    install: (app) => {
      app.provide(UpdaterSymbol, updater);
    },
    ...updater,
  };
}

export function useUpdater() {
  const updater = inject(UpdaterSymbol);

  if (!updater) {
    throw new Error("Call createUpdater before useUpdater");
  }

  return updater;
}
