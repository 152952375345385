import type { ModalManager } from "@verbleif/lib";
import type { defineComponent } from "vue";
import * as Sentry from "@sentry/vue";
import { api } from "@verbleif/shared";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

interface UseCreateArguments {
  component?: Promise<ReturnType<typeof defineComponent>>
  endpoint: string
  translationKey: string
  modalManager: ModalManager
  transformer?: ((input: any) => Promise<any>) | null
  load?: (() => Promise<any>) | null
  props?: Record<string, any>
}

export function useCreate({
  component,
  endpoint,
  translationKey,
  modalManager,
  load = null,
  transformer = null,
  props = {},
}: UseCreateArguments) {
  const { t } = useI18n();
  const { success, error } = useToast();

  function showCreate() {
    modalManager.open({
      component,
      options: {
        title: t(`${translationKey}.create_title`),
        onOk: create,
        okIcon: ["fas", "plus"],
        okText: t("base.create"),
        cancelText: t("base.cancel"),
      },
      componentProps: props,
    });
  }

  async function create(input: any) {
    modalManager.setLoading(true);

    if (!transformer) {
      throw new Error("No transformer given.");
    }

    const output = await transformer(input).catch((e) => {
      modalManager.setLoading(false);
      Sentry.setContext("error", e);
      Sentry.captureException(e);
      return e;
    });

    if (output instanceof Error) {
      console.error(output);
      return;
    }

    return api
      .post(endpoint, output)
      .then(() => {
        success(t(`${translationKey}.create_success`));
        modalManager.close();
      })
      .catch((e: any) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        const apiErrorMessage = e.response?.data?.violations?.[0]?.message;
        if (apiErrorMessage) {
          msg = apiErrorMessage;
        }
        error(t(msg, { errorCode: 0 }));
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        modalManager.setLoading(false);
        if (typeof load === "function") {
          load(); // Call load if it's a function
        }
      });
  }

  return {
    showCreate,
    create,
  };
}

export default useCreate;
